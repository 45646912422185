/**
 * KARD
 */

import React from "react";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";
import { Time } from "../../api/Utils";

/**
 */
const ProfileHistory = (props) => {
  const [isLoading, IsLoading] = React.useState(false);
  const [donList, SetDonList] = React.useState([]);
  /**
   */
  React.useEffect(() => {
    LoadDonation().catch((err) => {});
  }, []);
  /**
   */
  const LoadDonation = async () => {
    try {
      IsLoading(true);

      const resp = await props.Profile_Get_Donation();

      console.log("profile/history: LoadDonation: resp: ", resp);

      SetDonList(resp.Items);

      IsLoading(false);

      /// ...
      return {};
    } catch (err) {
      console.warn("profile/history: LoadDonation: err: ", err);

      IsLoading(false);

      return Promise.reject(err);
    }
  }; // LoadDonation

  return (
    <Col className="ml-auto mr-auto" xs="12" lg="12">
      <Row className="collections">
        <Col xs="12">
          <div className="card p-4 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project</th>
                  <th>Campaign</th>
                  <th>Amount</th>
                  {/*<th>Raised</th>*/}
                  {/* <th>Status</th> */}
                </tr>
              </thead>

              <tbody>
                {donList.map((don, idx) => (
                  <ListView don={don} />
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Col>
  );
}; // ProfileHistory

/**
 */
const ListView = (props) => {
  return (
    <tr key={props.don.tn}>
      <td>
        <a href="">{Time.Epoch2DateTime(props.don.tn)}</a>
      </td>
      <td>{props.don.pi.t}</td>
      <td>{props.don.ci.t}</td>
      <td>£{parseFloat(props.don.k.a / 100)}</td>
      {/* <td>
            <div className="switch_box box_1">
                <Switch
                offColor=""
                offText=""
                onColor=""
                onText=""
                ></Switch>
            </div>
            </td> */}
    </tr>
  );
}; // ListView

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(ProfileHistory);
