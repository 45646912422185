/**
 * @copyright KARD
 */

import React, { useEffect, useState } from "react";
import "../home/style.css";
import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Col,
  InputGroup,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { navigate } from "@reach/router";

import { connect, useDispatch } from "react-redux";
import * as actions from "rdx/actions";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import CreateSidebar from "./create_sidebar";
import { GetProjectCampaignList, GetProjectList } from "rdx/actions";

function CreateCampaignCategory(props) {
  const dispatch = useDispatch();
  const [ccampProject, CcampProject] = React.useState({});
  const [ccampCampaign, CcampCampaign] = React.useState({});
  const [pList, SetPList] = useState(
    props.__ccamp.project.proj ? [props.__ccamp.project.proj] : []
  );
  const [cProj, SetCProj] = useState(props.__ccamp.project.proj);
  const [cList, SetCList] = useState(
    props.__ccamp.project.camp ? [props.__ccamp.project.camp] : []
  );
  const [cCAmp, SetCCamp] = useState(props.__ccamp.project.camp);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  useEffect(() => {
    getProj().catch();
  }, []);

  useEffect(() => {
    if (cProj) {
      getCamp().then().catch();
    }
  }, [cProj]);
  useEffect(() => {
    console.log(cProj, cCAmp);
  }, [cProj, cCAmp]);

  async function getProj() {
    const { Items, success } = await dispatch(GetProjectList());
    if (success) {
      SetPList(Items);
    }
  }
  async function getCamp() {
    const { Items, success } = await dispatch(
      GetProjectCampaignList({ p_id: cProj.tn })
    );
    if (success) {
      SetCList(Items);
    }
  }

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="container-fluid">
          <div className="flex-xl-nowrap row">
            {/* Sidebar */}
            <CreateSidebar section="category" />

            {/* Body */}
            <main class="bd-content col-12 col-md-9 col-xl-8 pl-md-5 py-md-3">
              <div className="row">
                <div className="col-sm-8">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Campaign</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Category</BreadcrumbItem>
                  </Breadcrumb>
                </div>
                {/* <div className="col-sm-4">
                  <button className="btn btn-outline">Save Campaign</button>
                  <button className="btn btn-info">Review & Lunch</button>
                </div> */}
              </div>
              <h1 class="bd-title" id="content">
                Category
              </h1>
              <p className="text-muted">
                Choose the category that best suits you. If your project has
                several categories than choose the sub category option
              </p>
              <Col sm="12" className=" p-0 create-campaign">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    props.CrowdCampaign_Project({
                      proj: cProj,
                      camp: cCAmp,
                    });

                    navigate("/crowd/campaign/create/funding");
                  }}
                >
                  <FormGroup className="mb-3">
                    <label className="campTitle">Project *</label>
                    <InputGroup>
                      <select
                        className="form-control"
                        id="country"
                        value={cProj && cProj.tn}
                        onChange={(e) => {
                          // CcampProject({ t: e.target.value })
                          // console.log('SetCProj: e.target.value', e.target.value)

                          const pr_n_sel = parseInt(e.target.value, 10);

                          // console.log('SetCProj: pr_n_sel', pr_n_sel)

                          const proj_sel = pList.find((x) => x.tn === pr_n_sel);

                          // console.log('SetCProj: proj_sel', proj_sel)

                          // SetCProj(pList[e.target.value])
                          SetCProj(proj_sel);
                        }}
                        required
                      >
                        <option val="">Please select</option>
                        {pList.map((data) => {
                          return (
                            <option key={data.tn} value={data.tn}>
                              {data.p.t}
                            </option>
                          );
                        })}
                      </select>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <label className="campTitle">Campaign </label>
                    <InputGroup>
                      <Input
                        id="country"
                        type="select"
                        value={cCAmp && cCAmp.tn}
                        onChange={(e) => {
                          // CcampProject({ t: e.target.value })
                          // console.log(e.target.value)
                          // SetCCamp(cList[e.target.value])

                          // console.log('SetCCamp: e.target.value', e.target.value)

                          const cp_n_sel = parseInt(e.target.value, 10);

                          // console.log('SetCCamp: cp_n_sel', cp_n_sel)

                          const camp_sel = cList.find((x) => x.tn === cp_n_sel);

                          // console.log('SetCCamp: camp_sel', camp_sel)

                          SetCCamp(camp_sel);
                        }}
                        required
                      >
                        <option val="">Please select</option>
                        {cList.map((data) => {
                          return (
                            <option key={data.tn} value={data.tn}>
                              {data.ci.t}
                            </option>
                          );
                        })}
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <div className="btn-group">
                    <Button
                      className="mt-2 btn-lg text-center mr-2"
                      color="primary"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navigate("/crowd/campaign/create/basics");
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      className="mt-2 btn-lg btn-green text-center"
                      type="submit"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   e.stopPropagation();

                      //   props.CrowdCampaign_Project({
                      //     proj: ccampProject,
                      //     camp: ccampCampaign,
                      //   });

                      //   navigate("/crowd/campaign/create/funding");
                      // }}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </Form>
              </Col>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

// export default CreateCampaignCategory;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CreateCampaignCategory);
