/**
 * @copyright KARD
 */

import React from 'react'

import {
    makeStyles,
    Modal,
} from '@material-ui/core'

/**
 */
export default ( props ) =>
{
    /**
     */
    const getModalStyle = () =>
    {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    // const style_def = {
    //     position: 'absolute',
    //     // width: 400,
    //     maxWidth: '90%',
    //     backgroundColor: theme.palette.background.paper,
    //     border: '2px solid #000',
    //     boxShadow: theme.shadows[5],
    //     padding: theme.spacing(2, 4, 3),
    // }

    const style_mod = props.style_mod ? props.style_mod : {}

    /**
     */
    const useStyles = makeStyles((theme) => ({
        paper: {
            // ...style_def,
            ...{
                // flexGrow: 1,
                position: 'absolute',
                // width: 400,
                maxWidth: '90%',
                minWidth: '70%',
                maxHeight: '90%',
                minHeight: '70%',
                backgroundColor: theme.palette.background.paper,
                border: '2px solid #000',
                boxShadow: theme.shadows[5],
                padding: theme.spacing(2, 4, 3),
            },
            ...style_mod
        },
    }));

    const classes = useStyles();

    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Modal
            open={props.bOpen}
            onClose={props.OnClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
            {
                props.children
            }
            </div>
        </Modal>
    )
}   // Modal





