/**
 *	@copyright	KARD.
 */
import * as Cfg from './cfg'
import * as AWS from '../../api/AWS'
export const GetStripePK = (type)=>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )
            const p_list = {
                actType: 'charity',
                act: 'charity:get-stripe-pk',
                c_id:   gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    type
                }
            }
            console.log( 'actions/charity: GetCharityInfo: p_list: ', p_list )

            const {success,pk} = await aws_lambda.Invoke( p_list, lambda_name )

            console.log( 'actions/charity: GetCharityInfo: resp_list: ', success,pk )

            if (!pk)
            {
                throw new Error("Key not found")
            }

            return pk

            // return {msg: "ok",hasAccount:resp_list}
        }catch (e) {
            console.warn("action/charity: GetCharityInfo:error",e);
            return Promise.reject(e)
        }
    }
}

export const GetCharityInfo = () => {
    return async (d,gs)=>{
        try {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )
            const p_list = {
                actType: 'charity',
                act: 'charity:get-info',
                c_id:   gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }
            // console.log( 'actions/charity: GetCharityInfo: p_list: ', p_list )

            const {success,charity} = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/charity: GetCharityInfo: resp_list: ', success,charity )

            if (!charity)
            {
                throw new Error("Key not found")
            }
            d(_CharityInfo(charity))
            return charity

            // return {msg: "ok",hasAccount:resp_list}
        }catch (e) {
            console.warn("action/charity: GetCharityInfo:error",e);
            return Promise.reject(e)
        }
    }
}

/**
 */
const _CharityInfo = ( charity ) =>
{
    return {
        type: 'charity:set-info',
        payload: charity,
    }
}   // _Auth_Status
