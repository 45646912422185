/**
 * KARD
 */

import React from "react";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";
import { Time } from "../../api/Utils";
import { navigate } from "@reach/router";
/**
 */
const ProfileCampaigns = (props) => {
  const [isLoading, IsLoading] = React.useState(false);
  const [campList, SetCampList] = React.useState([]);
  /**
   */
  React.useEffect(() => {
    LoadCampaign().catch((err) => {});
  }, []);
  /**
   */
  const LoadCampaign = async () => {
    try {
      IsLoading(true);

      const resp = await props.Profile_Get_Campaign();

      console.log("profile/index: LoadCampaign: resp: ", resp);

      SetCampList(resp.Items);

      IsLoading(false);

      /// ...
      return {};
    } catch (err) {
      console.warn("profile/campaigns: LoadCampaign: err: ", err);

      IsLoading(false);

      return Promise.reject(err);
    }
  }; // LoadCampaign

  return (
    <Col className="ml-auto mr-auto" xs="12" lg="12">
      <Row className="collections">
        <Col xs="12">
          <div className="card p-4 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {/*<th>No</th>*/}
                  <th>Date</th>
                  <th>Campaign Name</th>
                  {/* <th>Project</th>
                            <th>Campaign</th> */}
                  <th>Target</th>
                  <th>Raised</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {campList.map((camp) => (
                  <ListView key={camp.uid} camp={camp} />
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Col>
  );
}; // ProfileCampaigns

/**
 */
const ListView = (props) => {
  // console.log(props)
  const OnEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(["/crowd/campaign/edit", encodeURI(props.camp.uid)].join("/"), {
      state: props.camp,
    });
  }; // OnEdit

  return (
    <tr key={props.camp.tn}>
      {/*<td>*/}
      {/*    <a href="">20101</a>*/}
      {/*</td>*/}
      <td>{Time.Epoch2DateTime(props.camp.tn)}</td>
      <td>{props.camp.t}</td>
      <td>£ {parseFloat(props.camp.a.t / 100).toFixed(2)}</td>
      <td>£ {parseFloat(props.camp.a.r / 100).toFixed(2)}</td>
      {/* <td>{props.camp.ci.s}</td> */}
      <td>
        <a className="btn btn-primary btn-sm mr-2" onClick={OnEdit}>
          <i className="fa fa-edit"></i>
        </a>
      </td>
    </tr>
  );
}; // ListView

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(ProfileCampaigns);
