/**
 * KARD
 */

import React, {useEffect, useRef, useState} from "react";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import Loader from "react-js-loader";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "rdx/actions";

// core components
import { uploadFile } from "../../rdx/actions/storage";
import {AuthChangePassword, SaveProfileImage} from "rdx/actions";

import * as AuiInfo from 'api/info'

import IndexNavbar from "components/Navbars/IndexNavbar.js";

import ProfileInfo from "./info";
import ProfileHistory from "./history";
import ProfileCampaigns from "./campaigns";
import Password from "../auth/password";

/**
 */
function UserProfile(props) {
  const PPRef = useRef();
  const orgId = useSelector((state) => state.__cfg.oId);

  // useSelector(state => console.log(state))

  const dispatch = useDispatch();

  const fIniS = {
    file: null,
    name: null,
    type: null,
    path: null,
  };

  const [pills, setPills] = React.useState("1");
  const [isSaving, IsSaving] = React.useState(false);
  const [isLoading, IsLoading] = React.useState(false);
  const [prof, SetProf] = React.useState({});
  const [doc, SetDoc] = useState(fIniS);
  const [pp, SetPP] = useState(null);

  const [name, Name] = React.useState('');
  const [oldPass, SetOldPass] = useState(null)
  const [newPass, SetNewPass] = useState(null)

  /**
   */
  React.useEffect(() => {
    LoadProfile().catch((err) => {});
  }, []);

  // React.useEffect(() => {
  //   console.log(PPRef);
  // }, [PPRef]);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  async function handleFileRead(event) {
    const { name, value } = event.target;
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    const doc = {
      file: file,
      type: file.type,
      name: file.name,
      path: url,
    };

    if( !file )
    {
      return { msg: "Image not found" };
    }

    SetPP(url);
    SetDoc({ ...doc });
  }

  /**
   */
  const LoadProfile = async () => {
    try {
      IsLoading(true);

      const resp = await props.Profile_Get_Info();

      console.log("profile/index: LoadProfile: resp: ", resp);

      SetProf(resp);

      if( resp.n )
      {
        Name( AuiInfo.Name(resp.n) )
      }

      if (resp.img)
      {
        SetPP(resp.img.f);
      }

      IsLoading(false);

      /// ...
      return {};
    } catch (err) {
      console.warn("profile/index: LoadProfile: err: ", err);

      IsLoading(false);

      return Promise.reject(err);
    }
  }; // LoadProfile

  /**
   */
  const UpdateInfo = async (p) => {
    try {
      IsLoading(true);

      console.log("UpdateInfo: p", p);

      const resp = await props.Profile_Update_Info(p);

      console.log("profile/index: UpdateInfo: resp: ", resp);

      if (resp.msg === "OK")
      {
        SetProf(resp.data.Attributes);

        if( resp.data.Attributes.n )
        {
          Name( AuiInfo.Name(resp.data.Attributes.n) )
        }
      }

      IsLoading(false);

      /// ...
      return {};
    } catch (err) {
      console.warn("profile/index: UpdateInfo: err: ", err);

      IsLoading(false);

      return Promise.reject(err);
    }
  }; // UpdateInfo

  // /**
  //  */
  // const ShowPhone = (p) => {
  //   if (p) return [p.cn, p.n].join("");
  //   else return "";
  // };

  // /**
  //  */
  // const ShowName = (name) => {
  //   if (name) return [name.t, name.f, name.l].join(" ");
  //   else return "";
  // };
  // /**
  //  */
  // const ShowAdr = (adr) => {
  //   if (adr) return [adr.l1, adr.l2, adr.c, adr.p, adr.pc, adr.cn].join(",");
  //   else return "";
  // };

  async function UpdatePassword(e) {
    try {
      e.preventDefault()
      console.log(newPass)

      if (!newPass?.match || !newPass?.valid)
      {
        return false;
      }
      const resp = await dispatch(AuthChangePassword(oldPass.p,newPass.p))
      console.log(resp)
    }catch (e) {
      console.warn(e)
    }
  }

  useEffect(()=>console.log(newPass,oldPass),[newPass,oldPass])

  async function saveImage() {
    try {
      IsSaving(true);

      const i_type = 'profile'

      const ft_split = doc.type.split("/")
      // const ft = ft_split.length > 1 ? ft_split.splice(-1, 1) : '';
      const ft = ft_split.length > 1 ? ft_split[ft_split.length-1] : '';
      // const name = [prof.u, Date.now()].join("_") + "." + ft;
      // const key = [orgId, "profile-pic", name].join("/");
      const key = [orgId, 'image', i_type, prof.u,
        [Date.now(), doc.name].join('_')
      ].join("/");

      const params = {
        i_type: i_type,
        gallery: 'profile',
        key: key,
        // bucket: "ec.publ.uk.eu-west-1-test",  // "ec.comm.uk.eu-west-1-test"

        name: doc.name,
        // name: name,
        type: ft,
        contentType: doc.type,
        file: doc.file,
      };

      // console.log(params, doc);
      console.log( 'UserProfile: saveImage: params: ', params )
      console.log( 'UserProfile: saveImage: doc: ', doc )

      const { success, url } = await dispatch(uploadFile(params));
      // const { success, url } = await dispatch(UploadFile2(params));

      console.log(url);

      if (success)
      {
        const { success, profile } = await dispatch(
          SaveProfileImage({ url, u: prof.u })
        );

        if (success)
        {
          SetProf(profile.data.Attributes.n);

          if( profile.data.Attributes.n )
          {
            Name( AuiInfo.Name(profile.data.Attributes.n) )
          }

          if (profile.data.Attributes.img)
          {
            SetPP(profile.data.Attributes.img.f);
          }

          SetDoc(fIniS);
        }

        console.log(profile.data.Attributes);
        // return {...resp}
      }
      else
      {
        throw new Error("Filed to save file!");
      }

      IsSaving(false);

      return {};
    } catch (e) {
      IsSaving(false);

      return Promise.reject(e);
    }
  }

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="profile">
          <Container>
            <Row>
              <div className="profile col-md-12">
                <div className="row mb-5">
                  <div className="col-sm-12">
                    <h4 className="projectTitle">
                      <i className="fa fa-user" /> Profile Home
                    </h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="profile-header">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="text-center">
                            {pp ? (
                              <img
                                style={{
                                  maxHeight: 230,
                                  minHeight: 230,
                                  objectFit: "cover",
                                }}
                                className="img"
                                src={pp}
                                alt="image"
                              />
                            ) : (
                              <img
                                style={{
                                  // width: 230,
                                  maxHeight: 230,
                                  minHeight: 230,
                                  objectFit: "cover",
                                }}
                                className="img"
                                src={require("assets/img/neutral.jpg").default}
                                alt="image"
                              />
                            )}

                            <h3 className="user-name m-t-0 mb-0">
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                style={{ display: "none" }}
                                id="contained-button-file"
                                ref={PPRef}
                                onChange={handleFileRead}
                              />
                              <label htmlFor="contained-button-file">
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    PPRef.current.click();
                                  }}
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                >
                                  Change Image
                                </Button>
                              </label>

                              {doc.file && (
                                <label htmlFor="contained-button-file">
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      saveImage().then().catch();
                                    }}
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Save Image
                                  </Button>
                                </label>
                              )}
                            </h3>

                            {/*<div
                              className="text-center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div className="staff-id mr-4">
                                Email: Nikjone@demoo.com
                              </div>
                              <div className="staff-id">Location: USA</div>
                              <div className="staff-id ml-4">
                                Phone: 001 2351 256 12
                              </div>
                            </div>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto mb-5" md="12">
                <h4 className="title text-center" style={{ fontWeight: 400 }}>
                  {" "}
                  {/* {ShowName(prof.n)} */}
                  {name}
                </h4>
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <div
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        <a className="btn btn-green btn-lg text-white">Info</a>
                      </div>
                    </NavItem>
                    <NavItem>
                      <div
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        <a className="btn btn-green btn-lg text-white">
                          Donate History
                        </a>
                      </div>
                    </NavItem>
                    <NavItem>
                      <div
                        className={pills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("3");
                        }}
                      >
                        <a className="btn btn-green btn-lg text-white">
                          My Campaigns
                        </a>
                      </div>
                    </NavItem>
                    <NavItem>
                      <div
                        className={pills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("4");
                        }}
                      >
                        <a className="btn btn-green btn-lg text-white">
                          Change Password
                        </a>
                      </div>
                    </NavItem>
                  </Nav>
                </div>
              </Col>

              <TabContent
                className="gallery"
                activeTab={"pills" + pills}
                style={{ width: "100%" }}
              >
                <TabPane tabId="pills1">
                  <ProfileInfo
                    prof={prof}
                    // ShowName={ShowName}
                    // ShowPhone={ShowPhone}
                    // ShowAdr={ShowAdr}
                    UpdateInfo={UpdateInfo}
                  />
                </TabPane>

                <TabPane tabId="pills2">
                  <ProfileHistory {...props} />
                </TabPane>

                <TabPane tabId="pills3">
                  <ProfileCampaigns {...props} />
                </TabPane>

                <TabPane tabId="pills4">
                  <form onSubmit={UpdatePassword}>
                    <Col className="ml-auto mr-auto" xs="12" lg="12">
                      <Row className="collections">
                        <Col xs="12">
                          <div className="card p-4">
                            <Password label="Old Password" requirement={false} confirmPass={false} getPassword={p=>SetOldPass(p)}/>
                            <Password label="New Password" requirement={true} confirmPass={true} getPassword={p=>SetNewPass(p)}/>
                            <button
                                className="btn btn btn-primary btn-rounded"
                                type='submit'
                            >
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </form>
                </TabPane>
              </TabContent>
            </Row>
          </Container>
        </div>
      </div>

      <>
        {(isSaving || isLoading) && (
          <div>
            <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
            >
              <Loader
                type="box-rectangular"
                bgColor={"#469b5a"}
                title={"Processing ..."}
                color={"#FFFFFF"}
                size={150}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
}

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(UserProfile);
