import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import CharityFooter from "components/Footers/NarrowFooter";

import { connect } from "react-redux";
import * as actions from "rdx/actions";
// import {Crowd_List} from "rdx/actions";

// sections for this page
import FundraisersLittlehope from "./fundraisers_littlehope";
import LiveLittlehope from "./live_littlehope";
import StartCampaign from "./start_campaign";
import CrowdHero from "./hero";
// import HeroSection from "./hero_section";

/**
 */
function Index(props)
{
  React.useEffect(() => {
    // Load().catch((err) => {});
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // const [isBusy, IsBusy] = React.useState(false);
  // const [list, List] = React.useState([]);
  // const [lastKey, LastKey] = React.useState(null);

  // /**
  //  */
  // const Load = async () => {
  //   try
  //   {
  //     // if( props )

  //     // IsBusy(true);

  //     const resp_list = await props.Crowd_List({
  //       b_more: false,
  //       // last_key: lastKey,
  //       n_elem: 6
  //     } );

  //     if( resp_list )
  //     {
  //       //
  //     }

  //     // console.log("crowd.index: Load: resp_list: ", resp_list);

  //     // List(resp_list.Items);
  //     // LastKey(resp_list.LastEvaluatedKey);

  //     // IsBusy(false);

  //     return {};
  //   }
  //   catch (err)
  //   {
  //     console.warn("crowd.index: Load: err: ", err);

  //     // IsBusy(false);

  //     return { err };
  //   }
  // }; // Load

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="main">
          <CrowdHero />

          <FundraisersLittlehope
            // list={list}
          />

          <LiveLittlehope />

          <StartCampaign />
        </div>
        <CharityFooter />
      </div>
    </>
  );
}

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Index);
