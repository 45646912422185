/**
 * @copyright KARD
 */

import React from "react";

// import {navigate} from "@reach/router";

// import {
// 	Grid,
// } from '@material-ui/core'

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

/**
 */
const ListPack = (props) => {
  const [listPack, ListPack] = React.useState(props.pk);

  //
  return (
    <div
    className="packModal"
      style={{
        overflow: "auto",
        overflowY: "scroll",
        // maxHeight: 600,
        // minHeight: 600,
      }}
    >
      <div>
        {listPack.map((x, i) => (
          <div key={i}>
            <div
              className="border border-primary rounded m-2 p-2"
              style={{
                backgroundColor:
                  props.pk && props.pk.tn === x.tn ? "lightgreen" : null,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.OnSelect(x);

                props.OnClose();
              }}
            >
              <h3
                className="text-left"
                style={{ fontSize: 18, color: "#060c27" }}
              >
                {x.n}
              </h3>
              {/* <hr/> */}
              <h5 style={{ fontSize: 14 }}>
                {"£"}
                {x.a} - {"rec" === x.p ? "Recurring" : "Once"}
              </h5>
            </div>
          </div>
        ))}
        <button
          // key={i}
          className="form-control"
          style={{
            margin: 5,
            padding: 5,
            backgroundColor: props.pk && props.pk.tn === -1 ? "#28a745" : null,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            props.OnSelect({ tn: -1, n: "Other", a: 0, p: "one" });
            props.OnClose();
          }}
        >
          {`Other: £`}
        </button>
      </div>
    </div>
  );
}; // ListPack
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(ListPack);
