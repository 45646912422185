/**
 *  @copyright KARD.
 */

// const aws_auth = {
exports.auth = {
    test: {
        region: 'eu-west-1',
        identityPoolRegion: 'eu-west-1',

        // identityPoolId: 'eu-west-1:eab323f4-c67f-452f-ba3d-9dfa9edf0a70',//Old
        identityPoolId: 'eu-west-1:c8ac4087-7a7d-4f88-a645-34e77fd4d033',
        // userPoolId: 'eu-west-1_syuTfzsoa',//
        userPoolId: 'eu-west-1_SbbvRxXF0',
        // userPoolWebClientId: '3pllnq628bjf7i3eah500otg34', //Old
        userPoolWebClientId: '4fmnsees9l8gnuqijk11n35s06',
    },
    live: {
        region: 'eu-west-1',
        identityPoolRegion: 'eu-west-1',

        identityPoolId: 'eu-west-1:d6830f8d-8e31-4725-83c6-20986b93293e',
        userPoolId: 'eu-west-1_x703ESljH',
        userPoolWebClientId: '85qpm57psjpsnlies60l89p6k',
    },
}

/**
 */
// export default aws_auth




