import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function SingleNews3() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/news/n3.jpg").default + ")",
            }}
          ></div>
        </div>

        <div className="section section-work">
          <Container>
            <Row>
              <Col sm="12">
                <h3 style={{ lineHeight: "35px" }}>
                  NIGERIAN LAW SCHOOL GRANT 2020-2021 ACADEMIC YEAR
                </h3>
                <img
                  alt="..."
                  className="rounded img-fluid my-4"
                  src={require("assets/img/news/allnews/n3.jpg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      The O. B. Lulu-Briggs Foundation’s N120,000 cash grant &
                      brand new laptop award ensures that financial constraints
                      don’t restrict prospective Rivers State students from
                      attending the Nigerian Law School.
                    </a>
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      The grant awards programs which began in 2009 is open to
                      all Rivers State indigenes who are already admitted into
                      the law school and who have achieved a Law degree with at
                      least Second-Class Upper Divison.
                    </a>

                    <a>
                      The requirement of the Second-Class Upper Division does
                      not apply to students who are of Kalabari origin.
                    </a>
                  </div>
                </Col>
                <br />
                <br />
                <Col lg="12" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <p>
                      For more information please follow us on social media:
                    </p>
                    <p> Facebook: fb.com/oblbf</p>{" "}
                    <p>Twitter: Twitter.com/oblbf </p>
                    <p>Instagram: Instagram.com/oblbf_ </p>
                    <p>Email: info@oblulubriggsfoundation.org </p>
                    <div className="workParagraph my-3">
                      #lawschool #students #grants #13thedition
                      #oblulubriggsfoundation #2021 #oblbf #philanthropy
                      #Education #Charity #Nigeria #Law
                    </div>
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleNews3;
