/**
 *	@copyright	KARD..
 */

import React from "react";
import { navigate, Link } from "@reach/router";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

import logo from "assets/img/ob-logo.png";
import "./navbar.css";

/**
 */
const IndexNavbar = (props) => {
  const [navbarColor, setNavbarColor] = React.useState("bg-white");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  /**
   */
  React.useEffect(() => {
    // console.log("IndexNavbar: props: ", props);

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("bg-white");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  /**
   */
  // React.useEffect( () => {
  //   console.log("IndexNavbar: props: ", props);
  // }, [props.__auth.status] )

  /**
   */
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top fixed-top-shadow" expand="lg" color="white">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="#" target="_blank" id="navbar-brand">
              <a href="/">
                <img src={logo} alt="Logo" style={{ width: "200px" }} />
              </a>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink tag={Link} to="/home">
                  Home
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink tag={Link} to="/crowd">
                  Crowd
                </NavLink>
              </NavItem> */}
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#pablo"
                  nav
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/work");
                  }}
                >
                  <p>Our Work</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/work/care-for-life" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Care for life
                  </DropdownItem>
                  <DropdownItem to="/work/free-medical-mission" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Free Medical Missions
                  </DropdownItem>
                  <DropdownItem to="/work/education-scholarship" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Education & Scholarships
                  </DropdownItem>
                  <DropdownItem to="/work/micro-credit" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Micro-credit and entrepreneurship
                  </DropdownItem>
                  <DropdownItem to="/work/portable-water" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Portable water and sanitation
                  </DropdownItem>
                  <DropdownItem to="/work/covid-19" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    COVID-19 intervention
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <p>Gallery</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/about/video" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Videos
                  </DropdownItem>
                  <DropdownItem to="/about/image" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Images
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink tag={Link} to="/news">
                  Current News
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/about/volunteer">
                  Volunteers
                </NavLink>
              </NavItem>

              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <p>About</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/about/us" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    About Us
                  </DropdownItem>

                  <DropdownItem to="/about/our-team" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Our Team
                  </DropdownItem>

                  <DropdownItem to="/about/contact" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Contact
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <NavItem>
                <Button
                  className="nav-link btn-border"
                  // href="#"
                  id="upgrade-to-pro"
                  // target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // navigate("/crowd/campaign/create/basics");
                    navigate("/crowd/campaign/create");
                  }}
                >
                  <p>Start A Campaign</p>
                </Button>
              </NavItem> */}
              {/* <NavItem>
                {"loggedin" === props.__auth.status ? (
                  <NavLink id="twitter-tooltip" tag={Link} to="/profile">
                    <i className="fa fa-user mr-1"> </i>
                    {" Profile"}
                  </NavLink>
                ) : (
                  <NavLink id="twitter-tooltip" tag={Link} to="/auth/login">
                    <i className="fa fa-user mr-1"> </i>
                    {" Sign In"}
                  </NavLink>
                )}
              </NavItem> */}
              {/* <NavItem>
                <NavLink tag={Link} to="/about/contact">
                  Contact Us
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}; // IndexNavbar

/**
 */
// export default IndexNavbar;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(IndexNavbar);
