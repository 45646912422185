/**
 */

import React from "react";
import "../home/style.css";
import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Col,
  InputGroup,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import Loader from "react-js-loader";

import { navigate, Link } from "@reach/router";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "rdx/actions";

// core components
// import AuiModal from "aui/modal";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Image from "aui/image";

import CreateSidebar from "./create_sidebar";
import { uploadFile } from "../../rdx/actions/storage";
import { SaveCrowdCampaignImage, SaveProfileImage } from "rdx/actions";
// import campaigns from "../profile/campaigns";

/**
 */
function CreateCampaignSummary(props)
{
  const [isBusy, IsBusy] = React.useState(false);
  const { content } = useSelector((state) => state.__ccamp);
  const orgId = useSelector((state) => state.__cfg.oId);
  const dispatch = useDispatch();

  /**
   */
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    console.log( "CreateCampaignSummary: props: ", props )
  }, []);

  /**
   */
  const CreateCampaign = async () => {
    try
    {
      // IsBusy(true);

      const { resp_create } = await props.CrowdCampaign_Create();

      console.log(
        "CreateCampaignSummary: CreateCampaign: resp_create: ",
        resp_create
      );

      if (resp_create)
      {
        //
      }

      //
      // IsBusy(false);

      return { resp_create };
    }
    catch (err)
    {
      console.warn("CreateCampaignSummary: CreateCampaign: err: ", err);

      // IsBusy(false);

      return Promise.reject(err);
    }
  }; // CreateCampaign

  /**
   */
  async function ProcessCampaign()
  {
    try
    {
      IsBusy(true);

      console.log( 'CreateCampaignSummary: ProcessCampaign: content: ', content )

      // const resp_content_img = {}

      /**
       */
      for( let i = 0; i < content.listDesc.length; ++i )
      {
        console.log( 'CreateCampaignSummary: ProcessCampaign: i: ', i )

        const d_obj = content.listDesc[i]

        console.log( 'CreateCampaignSummary: ProcessCampaign: d_obj: ', d_obj )

        if( 'img' === d_obj.t )
        {
          try
          {
            const i_type = 'gallery'

            // const aws_core = 
            const img = d_obj.d

            const ft_split = img.name.split(".")
            const ft = ft_split.length > 1 ? ft_split[ft_split.length-1] : '';
            // const name = ["crowd-campaign", p.id, Date.now()].join("_") + "." + ft;
            // const key = [orgId, "crowd-campaign", name].join("/");
            const key = [orgId, 'image', i_type, //prof.u,
              [Date.now(), img.name].join('_')
            ].join("/");

            const params = {
              i_type: i_type,
              // gallery: 'profile',
              // gallery_id: '',
              key: key,

              name: img.name,
              type: ft,
              contentType: img.type,
              file: img.file,
            };

            console.log('CreateCampaignSummary: saveImage: params: ', params);

            // const { success, url } = await dispatch(uploadFile(params));
            const resp_up = await dispatch( uploadFile(params) );

            // console.log(resp_up);
            console.log('CreateCampaignSummary: saveImage: resp_up: ', resp_up);

            // const resp_img = await saveImage( {}, d_obj.d )

            // console.log( 'CreateCampaignSummary: ProcessCampaign: resp_img: ', resp_img )

            // if( resp_img )
            // {
            //   //
            // }

            content.listDesc[i].d = {url: resp_up.url}
          }
          catch( err )
          {
            console.warn("CreateCampaignSummary: ProcessCampaign 2: err: ", err);
          }
        }

        // const resp_img = await saveImage()
      } // for i

      // return {}
      // throw new Error( 'Debugging break' )

      const { resp_create } = await CreateCampaign();

      console.log( 'CreateCampaignSummary: ProcessCampaign: resp_create: ', resp_create )

      if( !resp_create )
      {
        // IsBusy(false)
        // return { success: false, img: false };
        throw new Error( 'Failed to create campaign' )
      }

      const si = await saveImage( resp_create, content.profImg );

      IsBusy(false)

      // clear redux
      props.CrowdCampaign_Basic( {
        title: '',
        tagline: '',
        dateStart: '',
        dateEnd: '',
      } );

      props.CrowdCampaign_Project( {
        proj: null,
        camp: null,
      } );

      props.CrowdCampaign_Funding( {
        target: '',
      } );

      props.CrowdCampaign_Content( {
        profImg: {},
        profVid: '',
        listDesc: [],

        socFb: '',
        socInsta: '',
        socTwit: '',
        socYT: '',
      } );

      if (si)
      {
        return { success: true, img: true };
      }
      else
      {
        return { success: true, img: false };

        // throw new Error("Failed to update campaign image!")
      }
    }
    catch (err)
    {
      console.warn("CreateCampaignSummary: ProcessCampaign: err: ", err);

      IsBusy(false)

      return { success: false, img: false };
      // return Promise.reject(e)
    }
  }

  /**
   */
  async function saveImage( p, img )
  {
    try
    {
      console.log('CreateCampaignSummary: saveImage: p: ', p);
      console.log('CreateCampaignSummary: saveImage: img: ', img);

      const i_type = 'profile'

      // const aws_core = 

      const ft_split = img.name.split(".")
      const ft = ft_split.length > 1 ? ft_split[ft_split.length-1] : '';
      // const name = ["crowd-campaign", p.id, Date.now()].join("_") + "." + ft;
      // const key = [orgId, "crowd-campaign", name].join("/");
      const key = [orgId, 'image', i_type, //prof.u,
        [Date.now(), img.name].join('_')
      ].join("/");

      const params = {
        i_type: i_type,
        gallery: 'profile',
        // gallery_id: '',
        key: key,

        name: img.name,
        type: ft,
        contentType: img.type,
        file: img.file,
      };

      console.log('CreateCampaignSummary: saveImage: params: ', params);

      // const { success, url } = await dispatch(uploadFile(params));
      const resp_up = await dispatch( uploadFile(params) );

      // console.log(resp_up);
      console.log('CreateCampaignSummary: saveImage: resp_up: ', resp_up);

      if (!resp_up.success) {
        throw new Error("Filed to save file!");
      }

      // const { success, cc } = await dispatch(
      const resp_save = await dispatch(
        SaveCrowdCampaignImage({ url: resp_up.url, id: p.id })
      );

      // console.log(resp_save);

      if (!resp_save.success)
      {
        throw new Error("Filed to save file!");
      }

      return { cc: resp_save.cc.data.Attributes };
    }
    catch (err)
    {
      console.warn("CreateCampaignSummary: saveImage: err: ", err);

      return Promise.reject(err);
    }
  }

  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="container-fluid">
          <div className="flex-xl-nowrap row">
            {/* Sidebar */}
            <CreateSidebar section="summary" />

            {/* Body */}
            <main class="bd-content col-12 col-md-9 col-xl-8 pl-md-5 py-md-3">
              <div className="row">
                <div className="col-sm-8">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Campaign</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Summary</BreadcrumbItem>
                  </Breadcrumb>
                </div>
                {/* <div className="col-sm-4">
                  <button className="btn btn-outline">Save Campaign</button>
                  <button className="btn btn-info">Review & Lunch</button>
                </div> */}
              </div>
              <h1 class="bd-title" id="content">
                Summary
              </h1>
              <Col sm="12" className="create-campaign">
                <div className="row">
                  <Col sm="12" className="p-0 campaign-sumamry">
                    <Image
                      cn="responsive"
                      style={{
                        // width: "100%",
                        minHeight: 400,
                        maxHeight: 400,
                        objectFit: "cover",
                      }}
                      src={props.__ccamp.content?.profImg?.path}
                      alt={require("assets/img/placeholder.png").default}
                    />

                    <h4> Campaign Title</h4>
                    <p>{props.__ccamp.basic.title}</p>

                    <h4> Campaign Tagline</h4>
                    <p>
                      {props.__ccamp.basic.tagline}
                    </p>
                  </Col>
                  <Col sm="6" className=" p-0 campaign-sumamry">
                    <h4> Campaign Start Date</h4>
                    <p>{props.__ccamp.basic.dateStart}</p>
                  </Col>
                  <Col sm="6" className=" p-0 campaign-sumamry">
                    <h4> Campaign End Date</h4>
                    <p>{props.__ccamp.basic.dateEnd}</p>
                  </Col>

                  <Col md="6" className=" p-0 campaign-sumamry">
                    <h4> Project Name</h4>
                    <p>{props.__ccamp.project.proj?.p?.t}</p>
                  </Col>
                  <Col md="4" className=" p-0 campaign-sumamry">
                    <h4> Campaign Name</h4>
                    <p>{props.__ccamp.project.camp?.ci?.t}</p>
                  </Col>
                  <Col sm="6" className=" p-0 campaign-sumamry">
                    <h4> Target Amount</h4>
                    <p>{'£'+props.__ccamp?.funding?.target}</p>
                  </Col>
                  <Col sm="12" className=" p-0 campaign-sumamry">
                    <h4> Campaign Details</h4>
                    {/* <p>{'£'+props.__ccamp.funding.target}</p> */}
                    <>
                    {
                      props.__ccamp.content && props.__ccamp.content.listDesc && props.__ccamp.content.listDesc.map( x => (
                        <DescComp key={x.tn} {...x} />
                      ) )
                    }
                    </>
                  </Col>
                  <Col sm="6" className=" p-0 campaign-sumamry">
                    <h4>Social Links</h4>
                    <div className="mb-3">
                      <input
                        className="form-control mb-2"
                        type="text"
                        placeholder="http://facebook.com"
                        value={props.__ccamp.content.socFb}
                        disabled
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        placeholder="http://instagram.com"
                        value={props.__ccamp.content.socInsta}
                        disabled
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        placeholder="http://twitter.com"
                        value={props.__ccamp.content.socTwit}
                        disabled
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        placeholder="http://youtube.com"
                        value={props.__ccamp.content.socYT}
                        disabled
                      />
                    </div>
                  </Col>
                </div>
                <Form>
                  <img />
                  {/* <img /> // video */}
                  {[].map((x) => (
                    <div></div>
                  ))}

                  <div className="btn-group">
                    <Button
                      className="mt-2 btn-lg text-center mr-2"
                      color="primary"
                      // type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navigate("/crowd/campaign/create/content");
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      className="mt-2 btn-lg btn-green text-center"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        ProcessCampaign()
                          .then((p) => {
                            if (p.success) {
                              if (!p.img) {
                                alert(
                                  "Campaign Created. but failed to update image"
                                );
                                navigate("/crowd/campaign/create/thanks");
                              } else {
                                // alert("Campaign Created.");
                                navigate("/crowd/campaign/create/thanks");
                              }
                            }
                          })
                          .catch((e) => {
                            alert(e.message ? e.message : e);
                          });
                        //   navigate( '/crowd/campaign/create/content' )
                      }}
                    >
                      Create Campaign
                    </Button>
                  </div>
                </Form>
              </Col>
            </main>
          </div>
        </div>
      </div>

      <>
        {isBusy && (
          <div>
            <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
            >
              <Loader
                type="box-rectangular"
                bgColor={"#469b5a"}
                title={"Saving..."}
                color={"#FFFFFF"}
                size={150}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
} // CreateCampaignSummary

// export default CreateCampaignSummary
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CreateCampaignSummary);

/**
 */
const DescComp = (props) => {
  const [type, Type] = React.useState(props.t); // txt / img / vidUrl
  const [text, Text] = React.useState('txt' === props.t ? props.d : '');
  // const [imgDoc, ImgDoc] = React.useState( props.d );

  return (
    <>
      <>
        {"txt" === type ? (
          <p>{text}</p>
        ) : "img" === type ? (
          <div style={{marginTop: 15,}}>
            <Image
              src={props?.d.path}
              alt={"assets/img/placeholder.png"}
              altTxt="image"
              cn="border"
              style={{
                width: 400,
                maxHeight: 300,
                minHeight: 300,
                objectFit: "cover",
              }}
            />
          </div>
        ) : "vid" === type ? (
          <div>{/*  */}</div>
        ) : null}
      </>
    </>
  );
}; // DescComp

