import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// core components
const items = [
  {
    src: require("assets/img/works/care.png").default,
    title: "CARE FOR LIFE",
    description:
      "Care for Life, popularly called, Care for the Elderly Programme, was our first programme and it remains our flagship initiative. It identifies and caters to the indigent, sick and neglected senior citizens across Rivers State. We take care of their health, economic and social well-being by providing comprehensive healthcare; caregivers to look after them, monthly food supplies, and monthly cash allowances.",
    link: "/work/care-for-life",
  },
  {
    src: require("assets/img/works/free-medical.png").default,
    title: "FREE MEDICAL MISSIONS",
    description:
      "Since 2005, through 32 Free Medical Missions, we have taken comprehensive high-quality health services, including surgeries, to the doorsteps of 129,010 men, women and children.",
    link: "/work/free-medical-mission",
  },
  {
    src: require("assets/img/works/education.png").default,
    title: "EDUCATION",
    description:
      "Our Education and Scholarships Programme is helping to broaden access to and promote achievement in education, especially for students who would otherwise be shutout due to financial constraints.",
    link: "/work/education-scholarship",
  },
  {
    src: require("assets/img/works/micro-credit.png").default,
    title: "MICROFINANCE ",
    description:
      "Our Microfinance Programme promotes business development and job creation by providing women and youth in rural areas with start-up and working capital to set up new and grow existing micro, small and medium size businesses.",
    link: "/work/micro-credit",
  },
  {
    src: require("assets/img/works/water.png").default,
    title: "WASH",
    description:
      "Our Access to Potable Water and Sanitation Programme aim is to ensure that more rural communities have access to clean, safe and quality drinking water.",
    link: "/work/portable-water",
  },
  {
    src: require("assets/img/works/covid-19.png").default,
    title: "COVID-19 INTERVENTION",
    description:
      "The COVID-19 pandemic resulted in an unprecedented global social and economic disruption resulting in one of the largest global recessions which left governments, business leaders, health and scientific communities with the task of curbing it’s spread and impact.",
    link: "/work/covid-19",
  },
];

function AllWork() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>

          <div className="content-center">
            <Container>
              <h2 className="title text-white p-0">Our Work</h2>
            </Container>
          </div>
        </div>
        {/* Works list */}
        <div className="section section-works">
          <Container>
            {items.map((item) => {
              return (
                <Row style={{ marginBottom: "8rem" }}>
                  <Col sm="12" lg="6">
                    <img
                      alt="..."
                      className="rounded img-fluid"
                      src={item.src}
                    ></img>
                  </Col>
                  <Col sm="12" lg="6">
                    <h2 className="text-uppercas title">{item.title}</h2>
                    <p className="work_description">{item.description}</p>
                    {/* <a href={item.link} className="btn btn-info">
                      <i className="fa fa-arrow-right" /> See Details
                    </a> */}
                    <a
                      className="btn btn-info text-white"
                      color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navigate(item.link);
                      }}
                    >
                      View Details
                    </a>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default AllWork;
