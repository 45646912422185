import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function SingleNews6() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/news/n6.jpeg").default + ")",
            }}
          ></div>
        </div>

        <div className="section section-work">
          <Container>
            <Row>
              <Col sm="12">
                <h3 style={{ lineHeight: "35px" }}>
                  O. B. LULU-BRIGGS FOUNDATION MARKS 2 DECADES OF IMPROVING THE
                  LIVES OF UNDER-SERVED PERSONS
                </h3>
                <img
                  alt="..."
                  className="rounded img-fluid img-raised my-4"
                  src={require("assets/img/news/n6.jpeg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      The O. B. Lulu-Briggs Foundation, one of Nigeria’s leading
                      humanitarian organizations has clocked 20 years of
                      charitable work in the country.
                    </a>
                    <br />
                    <br />
                    <a>
                      Founded on the 21st of September 2001 by Dr. Mrs. Seinye
                      O. B. Lulu-Briggs, to institutionalize the legendary
                      philanthropy of her husband, celebrated nationalist and
                      statesman, High Chief Dr. O. B. Lulu-Briggs of blessed
                      memory, the foundation has in the last two decades, in
                      alignment with global goals of the United Nations, gone on
                      to leave indelible imprints in the lives of hundreds of
                      thousands of people across the country, especially in
                      communities within Nigeria’s Niger delta region.
                    </a>
                    <br />
                    <br />
                    <a>
                      With a mission to implement sustainable interventions that
                      enhance the quality of life for under-served persons, the
                      O. B. Lulu-Briggs Foundation continues to offer hope,
                      succour and support to at-risk and vulnerable persons. It
                      achieves its mission through 5 distinct programmes which
                      include its flagship eldercare programme: Care For Life
                      and its Free Medical Mission, a 5 day pop-up health camps
                      held in peri-urban and rural communities.
                    </a>
                    <br />
                    <br />
                    <a>
                      According to a statement released by Mr. Oraye St.
                      Franklyn, Spokesperson to Dr. Seinye Lulu-Briggs, the
                      Chairman Board of Trustees of the O. B. Lulu-Briggs
                      Foundation, the 20th anniversary celebration would be
                      marked by a series of yearlong events that begin with a
                      thanksgiving service on the 21st of September 2021 at the
                      Chapel of God Int’l worship center in Port Harcourt.
                    </a>
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      “A one-day Free Eye Clinic will also hold on Thursday the
                      23rd of September 2021 to offer free eye care to members
                      of the public. Other events lined up for the yearlong
                      celebrations are a Law Clinic scheduled for the 24th of
                      November 2021. The Law Clinic will ride on the back of the
                      foundation’s landmark investments in law education in the
                      last 13 years. It would see members of the Foundation’s
                      Law School Grants Alumni, in the company of legal
                      luminaries, giving back to society through pro bono
                      services on that day,” Oraye revealed.
                    </a>
                    <br />
                    <br />
                    <a>
                      The statement also enjoined members of the public to take
                      advantage of the free eye clinic for their eye checks and
                      treatment.
                    </a>
                    <a>
                      The high point of the 20th anniversary celebrations
                      according to Mr. St Franklyn would be an anniversary
                      lecture and gala night to hold in February 2022.
                    </a>
                    <br />
                    <br />
                    <a>
                      According to him, the gala night would appreciate the
                      volunteers, staff and partners of the foundation who have
                      contributed immensely to its 20-year success. The event
                      would also signpost the direction of the foundation for
                      the next twenty years especially in the face of new and
                      emerging global humanitarian challenges.
                    </a>
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleNews6;
