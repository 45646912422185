/**
 *	@copyright	KARD.
 */

// import * as Cfg from '../../_cfg'

/**
 */
export const Stage = ( cfg_stage) =>
{
    return {
        type: 'cfg:stage',
        payload: cfg_stage,
    }
}   // Stage

/**
 */
export const DDB = ( db_name ) =>
{
    return ( _d, gs ) =>
    {
        // return gs().__cfg.db( db_name, gs().__cfg.stage )
        return gs().__cfg.ddb[gs().__cfg.stage][gs().__cfg.cn][db_name]
    }
}   // DDB

/**
 */
export const Lambda = ( lambda_name ) =>
{
    return ( _d, gs ) =>
    {
        // console.log( 'rdx/cfg: Lambda: Cfg: ', Cfg )
        // console.log( 'rdx/cfg: Lambda: Cfg.core: ', Cfg.core )
        // console.log( 'rdx/cfg: Lambda: Cfg.core.stage: ', Cfg.core.stage )

        // console.log( 'rdx/cfg: Lambda: gs().__cfg: ', gs().__cfg )
        // console.log( 'rdx/cfg: Lambda: gs().__cfg.stage: ', gs().__cfg.stage )
        // console.log( 'rdx/cfg: Lambda: gs().__cfg.cn: ', gs().__cfg.cn )
        // console.log( 'rdx/cfg: Lambda: gs().__cfg.lambda( gs().__cfg.cn, gs().__cfg.stage ): ', gs().__cfg.lambda( gs().__cfg.cn, gs().__cfg.stage ) )
        // console.log( 'rdx/cfg: Lambda: gs().__cfg.lambda: ', gs().__cfg.lambda )

        // return gs().__cfg.db( db_name, gs().__cfg.stage )
        // return gs().__cfg.lambda[gs().__cfg.stage][gs().__cfg.cn][lambda_name]
        return gs().__cfg.lambda( gs().__cfg.cn, gs().__cfg.stage )[lambda_name]
    }
}   // Cfg_Lambda


