import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Container,
  Row,
} from "reactstrap";
// core components
const items = [
  {
    src: require("assets/img/fund.jpeg").default,
    title: "San Antonio, TX",
    title2: "Gabe's memorial fund",
    description:
      "I dont know whats real and not real any more but unfortunately gabe",
    lastdonatehr: "6",
    targetamount: "1000",
    receiveamount: "700",
  },
  {
    src: require("assets/img/fund-2.jpeg").default,
    title: "San Antonio, TX",
    title2: "Gabe's memorial fund",
    description:
      "I dont know whats real and not real any more but unfortunately gabe",
    lastdonatehr: "15",
    targetamount: "1000",
    receiveamount: "500",
  },
  {
    src: require("assets/img/fund-3.jpeg").default,
    title: "San Antonio, TX",
    title2: "Gabe's memorial fund",
    description:
      "I dont know whats real and not real any more but unfortunately gabe",
    lastdonatehr: "9",
    targetamount: "1000",
    receiveamount: "220",
  },
  {
    src: require("assets/img/fund.jpeg").default,
    title: "San Antonio, TX",
    title2: "Gabe's memorial fund",
    description:
      "I dont know whats real and not real any more but unfortunately gabe",
    lastdonatehr: "6",
    targetamount: "1000",
    receiveamount: "700",
  },
  {
    src: require("assets/img/fund-2.jpeg").default,
    title: "San Antonio, TX",
    title2: "Gabe's memorial fund",
    description:
      "I dont know whats real and not real any more but unfortunately gabe",
    lastdonatehr: "15",
    targetamount: "1000",
    receiveamount: "500",
  },
  {
    src: require("assets/img/fund-3.jpeg").default,
    title: "San Antonio, TX",
    title2: "Gabe's memorial fund",
    description:
      "I dont know whats real and not real any more but unfortunately gabe",
    lastdonatehr: "9",
    targetamount: "1000",
    receiveamount: "220",
  },
];

function Programs() {
  return (
    <>
      <div className="section section-basic" id="basic-elements">
        <Container>
          <h2 className="title mt-4 mb-4" style={{ paddingTop: "0px" }}>
            Our Programs
          </h2>
          <Row>
            {items.map((item) => {
              return (
                <div className="col-md-4 col-sm-12">
                  <Card>
                    <img
                      alt="..."
                      className="rounded img-raised"
                      src={item.src}
                    ></img>
                    <CardBody>
                      <CardTitle tag="h4" className="text-uppercase">
                        {item.title}
                      </CardTitle>
                      <CardTitle tag="h5">{item.title2}</CardTitle>
                      <CardText>{item.description}</CardText>
                      <Button className="btn btn-info">View Program</Button>
                    </CardBody>
                  </Card>
                </div>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Programs;
