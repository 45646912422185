/**
 * @copyright KARD
 */

import React from "react";
import "../home/style.css";
import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Col,
  InputGroup,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

/**
 */
const CreateSidebar = (props) => {
  return (
    <div class="bd-sidebar col-12 col-md-3 col-xl-2">
      <nav class="collapse bd-links" id="bd-docs-nav">
        <div class="bd-toc-item active">
          <a
            class="bd-toc-link"
            style={{
              backgroundColor: "basic" === props.section ? "#026666" : null,
              color: "basic" === props.section ? "#fff" : null,
            }}
          >
            <i className="fa fa-user-edit" />
            Basic
          </a>

          <a
            class="bd-toc-link"
            style={{
              backgroundColor: "category" === props.section ? "#026666" : null,
              color: "category" === props.section ? "#fff" : null,
            }}
          >
            <i className="fa fa-list-alt" />
            Category
          </a>

          <a
            class="bd-toc-link"
            style={{
              backgroundColor: "funding" === props.section ? "#026666" : null,
              color: "funding" === props.section ? "#fff" : null,
            }}
          >
            <i className="fa fa-outdent" />
            Funding
          </a>

          <a
            class="bd-toc-link"
            style={{
              backgroundColor: "content" === props.section ? "#026666" : null,
              color: "content" === props.section ? "#fff" : null,
            }}
          >
            <i className="fa fa-list" />
            Content
          </a>

          <a
            class="bd-toc-link"
            style={{
              backgroundColor: "summary" === props.section ? "#026666" : null,
              color: "summary" === props.section ? "#fff" : null,
            }}
          >
            <i className="fa fa-list-alt" />
            Summary
          </a>
        </div>
      </nav>
    </div>
  );
}; // CreateSidebar

/**
 */
export default CreateSidebar;
