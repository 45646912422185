/**
 * @copyright KARD
 */

import React, { useEffect } from "react";
import "../home/style.css";
import "./style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

// core components
// import IndexNavbar from "components/Navbars/IndexNavbar.js";
// import NarrowFooter from "components/Footers/NarrowFooter.js";
// import { navigate } from "@reach/router";

import {
  GetCrowdCampaignDetails,
  Crowd_DonationList,
  Crowd_Profile,
} from "../../rdx/actions";

import { useDispatch } from "react-redux";

// import Image from "aui/image";

/**
 */
const LatestDonations = (props) => {
  const dispatch = useDispatch();

  const [isBusy, IsBusy] = React.useState(false);
  const [list, List] = React.useState([]);

  /**
   */
  React.useEffect(() => {
    Init().catch((err) => {});
  }, [props.id]);

  /**
   */
  const Init = async () => {
    try {
      if (!props.id) {
        return {};
      }

      IsBusy(true);

      const resp_list = await dispatch(Crowd_DonationList({ id: props.id }));

      // console.log("LatestDonations: Init: resp_list: ", resp_list);

      List(resp_list.Items);
      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("LatestDonations: Init: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Init

  /**
   */
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Recent Donor</CardTitle>
        {/* <CardText>{item.description}</CardText> */}
        <div className="recent-donor">
          {list.map((x) => (
            <LatestDonations_ListView dont={x} key={x.d} />
          ))}
        </div>
        <div className="mt-3 col btn-group btn-group-justified">
          <a
            href=""
            className="btn btn-green mr-1"
            data-toggle="modal"
            data-target="#exampleModalLong"
          >
            See All
          </a>
          <a href="" className="btn btn-green text-white">
            Donate
          </a>
        </div>
      </CardBody>
    </Card>
  );
}; // LatestDonations

/**
 */
const LatestDonations_ListView = (props) => {
  const dispatch = useDispatch();

  const [isBusy, IsBusy] = React.useState(false);
  const [profName, ProfName] = React.useState("");
  const [profImg, ProfImg] = React.useState(null);

  const [dontAmount, DontAmount] = React.useState("");
  const [dontMsg, DontMsg] = React.useState("");

  React.useEffect(() => {
    Init().catch((err) => {});
  }, []);

  const Init = async () => {
    try {
      IsBusy(true);

      // console.log("LatestDonations_ListView: Init: props: ", props);

      if (props.dont.u) {
        const prof_donr = await dispatch(
          Crowd_Profile({ username: props.dont.u })
        );

        // console.log("LatestDonations_ListView: Init: prof_donr: ", prof_donr);

        if (prof_donr.Item.n && prof_donr.Item.n.f && prof_donr.Item.n.l) {
          ProfName([prof_donr.Item.n.f, prof_donr.Item.n.l].join(" "));
        }

        if (prof_donr.Item.img && prof_donr.Item.img.f) {
          ProfImg(prof_donr.Item.img.f);
        }
      } else {
        ProfName("Anonymous");
      }

      try {
        DontAmount(props.dont.k.a.toFixed(2));
      } catch (err) {}

      if (props.dont.msg) {
        DontMsg(props.dont.msg);
      }

      IsBusy(false);

      return {};
    } catch (err) {
      console.log("LatestDonations_ListView: Init: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Init

  // Modal
  <div
    className="modal fade"
    id="exampleModalLong"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Modal title
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          ras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
          <button type="button" className="btn btn-primary">
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>;
  // End Modal

  return (
    <div className="all-donor">
      <div
        className="text-green pb-2 pt-2"
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div className="donor-img">
          <>
            {profImg ? (
              <img
                alt="Profile Image"
                className="responsive mr-3"
                src={profImg}
                style={{
                  borderRadius: "50%",
                  flexShrink: 0,
                  width: "30px",
                  maxHeight: "40px",
                  minHeight: "40px",
                }}
              />
            ) : (
              <img
                alt="Avatar"
                className="responsive mr-3"
                src={require("assets/img/neutral.jpg").default}
                style={{
                  borderRadius: "50%",
                  flexShrink: 0,
                  width: "30px",
                  maxHeight: "40px",
                  minHeight: "40px",
                }}
              />
            )}
          </>
        </div>
        <div className="donor-name-amnt">
          <a>{profName}</a>
          <p className="p-0 m-0 font-weight-bold">
            £{dontAmount} <span className="pl-3">10 Dec 2021</span>
          </p>
        </div>
      </div>
      <div
        className="donor-msg pb-1"
        style={{
          borderBottom: "1px solid #e9ecef",
        }}
      >
        {dontMsg.length ? <p className="p-0 m-0">{dontMsg}</p> : null}
      </div>
    </div>
  );
}; // LatestDonations_ListView

/**
 */
export default LatestDonations;
