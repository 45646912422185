/**
 * @copyright KARD
 */

import React from "react";
import "../home/style.css";
import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Col,
  InputGroup,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

// import {
//   Campaign_Get,
//   Donation_Notify,
//   DonationNew,
//   Donor_New,
//   PaymentReceived,
//   Project_Get,
// } from "rdx/actions";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import CreateSidebar from "./create_sidebar";

/**
 */
function CreateCampaign_Basic(props)
{
  const [isBusy, IsBusy] = React.useState(false);
  const [title, Title] = React.useState(props.__ccamp.basic.title);
  const [tagline, Tagline] = React.useState(props.__ccamp.basic.tagline);
  const [dateStart, DateStart] = React.useState( props.__ccamp.basic.dateStart )
  const [dateEnd, DateEnd] = React.useState( props.__ccamp.basic.dateEnd )

  //

  /**
   */
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  /**
   */
  React.useEffect(() => {
    Init().catch((err) => {});
  }, []);

  /**
   */
  const Init = async () => {
    try {
      IsBusy(true);

      const resp_all = await Promise.all([LoadProj(), LoadCamp()]);

      if (resp_all) {
        //
      }

      props.CrowdCampaign_Project({
        proj: resp_all[0],
        camp: resp_all[1],
      });

      IsBusy(false);

      return {};
    } catch (err) {
      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Init

  /**
   */
  const LoadProj = async () => {
    try {
      if (!props.pr_n) {
        return null;
      }

      const resp_proj = await props.Project_Get({
        pr_n: parseInt(props.pr_n, 10),
      });

      if (resp_proj) {
        //
      }

      return resp_proj.Item;
    } catch (err) {
      console.warn("CreateCampaign_Basic: LoadProj: err: ", err);

      return { err };
    }
  }; // LoadProj

  /**
   */
  const LoadCamp = async () => {
    try {
      if (!props.cp_n) {
        return null;
      }

      const resp_camp = await props.Campaign_Get({
        pr_n: parseInt(props.pr_n, 10),
        cp_n: parseInt(props.cp_n, 10),
      });

      if (resp_camp) {
        //
      }

      return resp_camp.Item;
    } catch (err) {
      console.warn("CreateCampaign_Basic: LoadProj: err: ", err);

      return { err };
    }
  }; // LoadCamp

  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="container-fluid">
          <div className="flex-xl-nowrap row">
            {/* Sidebar */}
            <CreateSidebar section="basic" />

            {/* Body */}
            <main class="bd-content col-12 col-md-9 col-xl-8 pl-md-5 py-md-3">
              <div className="row">
                <div className="col-sm-8">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Campaign</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Basics</BreadcrumbItem>
                  </Breadcrumb>
                </div>
                {/* <div className="col-sm-4">
                  <button className="btn btn-outline">Save Campaign</button>
                  <button className="btn btn-info">Review & Lunch</button>
                </div> */}
              </div>
              <h1 class="bd-title" id="content">
                Basics
              </h1>
              <p>
                Make A Good First Impression: Title and tagline matters. This
                basic information will represent your campaign on your campaign
                page, on your campaign card, and in searches.
              </p>
              <Col sm="12" className=" p-0 create-campaign">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    props.CrowdCampaign_Basic({
                      title,
                      tagline,
                      dateStart,
                      dateEnd,
                    });

                    navigate("/crowd/campaign/create/category");
                  }}
                >
                  <FormGroup className="mb-5">
                    <label className="campTitle">Campaign Title *</label>
                    <Input
                      aria-describedby="emailHelp"
                      id="campTitle"
                      placeholder="Education"
                      type="text"
                      required
                      value={title}
                      onChange={(e) => Title(e.target.value)}
                    ></Input>
                  </FormGroup>
                  <FormGroup className="mb-5">
                    <label className="campTitle">Campaign Tagline *</label>
                    <p className="text-muted">
                      Provide a short description that best describes your
                      campaign to your audience.
                    </p>
                    <Input
                      id="campTag"
                      placeholder="Education for All"
                      type="text"
                      required
                      value={tagline}
                      onChange={(e) => Tagline(e.target.value)}
                    ></Input>
                  </FormGroup>

                  <FormGroup className="mb-5">
                    <label className="campTitle">Campaign Start Date *</label>

                    <Input
                      id="camp_Sdate"
                      placeholder="date"
                      type="date"
                      required
                      value={dateStart}
                      onChange={ e => {
                        // console.log( 'start date: e.target.value: ', e.target.value )
                        DateStart( e.target.value )
                      } }
                    ></Input>
                  </FormGroup>

                  <FormGroup className="mb-5">
                    <label className="campTitle">Campaign End Date *</label>

                    <Input
                      id="camp_Edate"
                      placeholder="date"
                      type="date"
                      required
                      value={dateEnd}
                      onChange={ e => {
                        // console.log( 'end date: e.target.value: ', e.target.value )
                        DateEnd( e.target.value )
                      } }
                    ></Input>
                  </FormGroup>

                  <Button
                    className="mt-2 btn-lg btn-green text-center"
                    type="submit"

                    // onClick={ e => {
                    //   e.preventDefault()
                    //   e.stopPropagation()

                    //   props.CrowdCampaign_Basic( {title, tagline} )

                    //   navigate( '/crowd/campaign/create/category' )
                    // } }
                  >
                    Save & Continue
                  </Button>
                </Form>
              </Col>
            </main>
          </div>
        </div>
      </div>
    </>
  );
} // CreateCampaign_Basic

// export default CreateCampaign_Basic;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CreateCampaign_Basic);
