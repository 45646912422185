import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function MicroCredit() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/microcredit/mc-3.jpg").default +
                ")",
            }}
          ></div>

          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white ">
                Microfinance
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext/1642076915/1642077189162");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p>
                  Empowering and uplifting communities is a key part of our
                  work. One way in which we do this, is through our Microfinance
                  programme which uses a sustainable approach in order to
                  develop the socio-economic wellbeing of a community, through
                  providing entrepreneurship training, skills and knowledge to
                  members of the community in order to create livelihood
                  opportunities for all.
                </p>
                <p>
                  Our Micro-credit and Entrepreneurship Programme promotes
                  business development and job creation by providing women and
                  youth in rural areas with start-up and working capital to set
                  up new and grow existing micro, small and medium size
                  businesses. Training on how to establish, manage and grow such
                  businesses is provided through this programme, as well as
                  interest-free revolving loans being extended to cooperatives
                  and organized groups.
                </p>

                <img
                  alt="..."
                  className="rounded img-fluid center-img"
                  src={require("assets/img/works/microcredit/mc.jpg").default}
                ></img>
                <p className="mt-5">
                  Youth are provided with skills which improve their
                  employability and micro and small business owners are
                  empowered by undergoing trainings and skills acquisition in a
                  number of areas. This includes pipeline fittings, crane
                  operations, plumbing, electrical work, carpentry, tailoring,
                  hair dressing, soap making, computer and digital literacy.
                  This broad range of areas meets the diverse needs, skills and
                  interests of the community, catering to multiple fields and
                  professions in order to enable individuals to realise their
                  goals and fulfil their potential.
                </p>
                <p>
                  So far more than 1,000 women and youth have benefitted from
                  this programme which we hope to expand and develop in the
                  future, to ensure that jobs, livelihoods, and economic
                  security is awarded to all, and not the privileged few. Access
                  to training, employment and education is a key avenue through
                  which societies can progress and address social and economic
                  inequality. Among the Sustainable Development Goals, SDG 8,
                  working toward Decent Work and Economic Growth speaks to this
                  notion. SDG 8 seeks to promote sustained, inclusive and
                  sustainable economic growth, full and productive employment
                  and decent work for all. We hope that through our efforts, we
                  can begin to pave the way for continued economic growth in
                  Nigeria, and beyond, by providing essential training and
                  skills to those who need it most.
                </p>
                {/* <a
                  className="btn btn-lg text-white pt-3 cmpBtn"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/donation/ext/1642076915/1642077189162");
                  }}
                >
                  Donate
                </a> */}
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default MicroCredit;
