import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter, Route, Switch, Redirect } from "@reach/router";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

import App from "./App";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// return {}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// // pages for this kit
// import Index from "views/Index.js";
// // import NucleoIcons from "views/NucleoIcons.js";
// import LoginPage from "views/Login/LoginPage.js";
// import LandingPage from "views/Home/LandingPage.js";
// import ProfilePage from "views/Home/ProfilePage.js";
// import RegistrationPage from "views/Registration/RegistrationPage.js";
// import RegistrationSecond from "views/Registration/RegistrationPage2";
// import ForgotPassword from "views/Registration/ForgotPassword";
// import StartCampaign from "views/Campaign/StartCampaign";
// import StartCampaignEdit from "views/Campaign/StartCampaignEdit";

// ReactDOM.render(
//   <BrowserRouter>
//     <Switch>
//       <Switch>
//         <Route path="/" render={(props) => <Index {...props} />} />
//         {/* <Route
//           path="/nucleo-icons"
//           render={(props) => <NucleoIcons {...props} />}
//         /> */}
//         <Route path="/landing" render={(props) => <LandingPage {...props} />} />
//         <Route path="/profile" render={(props) => <ProfilePage {...props} />} />
//         <Route
//           path="/registration"
//           render={(props) => <RegistrationPage {...props} />}
//         />
//         <Route
//           path="/registration2"
//           render={(props) => <RegistrationSecond {...props} />}
//         />
//         <Route path="/login" render={(props) => <LoginPage {...props} />} />
//         <Route
//           path="/forgot-password"
//           render={(props) => <ForgotPassword {...props} />}
//         />
//         <Route
//           path="/campaign"
//           render={(props) => <StartCampaign {...props} />}
//         />
//         <Route
//           path="/campaign-edit"
//           render={(props) => <StartCampaignEdit {...props} />}
//         />
//         <Redirect to="/" />
//         <Redirect from="/" to="/" />
//       </Switch>
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
