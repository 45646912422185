/**
 * KARD
 */

import React from "react";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  InputGroup,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

import * as AuiInfo from 'api/info'

/**
 */
const ProfileInfo = (props) => {
  // console.log("props.prof ", props.prof);

  const [iEdit, setEdit] = React.useState(false);

  const [name, Name] = React.useState({});
  const [addr, Address] = React.useState({});
  const [phone, Phone] = React.useState({});

  const [nameShow, NameShow] = React.useState('');
  const [emailShow, EmailShow] = React.useState('');
  const [phoneShow, PhoneShow] = React.useState('');
  const [addrShow, AddrShow] = React.useState('');

  // const [nameEdit, NameEdit] = React.useState({});
  // const [addrEdit, AddressEdit] = React.useState({});
  // const [phoneEdit, PhoneEdit] = React.useState({});

  /**
   */
  React.useEffect(() => {
    Name(props.prof && props.prof.n ? props.prof.n : {});
    Address(props.prof && props.prof.a ? props.prof.a : {});
    Phone(props.prof && props.prof.p ? props.prof.p : {cn: '44', n: ''});

    const name_show = AuiInfo.Name( props.prof && props.prof.n ? props.prof.n : {} )
    const addr_show = AuiInfo.AddrFull( props.prof && props.prof.a ? props.prof.a : {} )
    const phone_show = AuiInfo.Phone( props.prof && props.prof.p ? props.prof.p : {} )

    NameShow( name_show && name_show.length ? name_show : 'Not provided' )
    EmailShow( props.prof && props.prof.e ? props.prof.e : 'Not provided' )
    AddrShow( addr_show && addr_show.length ? addr_show : 'Not provided' )
    PhoneShow( phone_show && phone_show.length ? phone_show : 'Not provided' )
  }, [props.prof]);

  // console.log("props.prof ", name);

  /**
   */
  const UpdateInfo = async (e) => {
    try
    {
      e.preventDefault();
      e.stopPropagation();

      await props.UpdateInfo({
        name,
        addr,
        phone,
      });

      setEdit(false);

      return {};
    }
    catch (err)
    {
      console.warn("profile/info: UpdateInfo: err: ", err);

      return Promise.reject(err);
    }
  }; // UpdateInfo

  return (
    <Col className="ml-auto mr-auto" xs="12" lg="12">
      <Row className="collections">
        {iEdit ? (
          <Col xs="12">
            <div className="card p-4">
              <div>
                <Form>
                  {/* <FormGroup>
                    <label htmlFor="exampleInputEmail1">First Name</label>
                    <InputGroup>
                      <Input
                        placeholder="John"
                        type="text"
                        value={name.f}
                        onChange={(e) => Name({ ...name, f: e.target.value })}
                      />
                    </InputGroup>
                  </FormGroup> */}

                  <FormGroup>
                    <h5>Name</h5>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Title
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <select
                            className="form-control"
                            // required
                            value={name.t}
                            onChange={(e) =>
                              Name({ ...name, t: e.target.value })
                            }
                          >
                            <option value="">Select</option>
                            <option value="mr">Mr</option>
                            <option value="ms">Ms</option>
                            <option value="mrs">Mrs</option>
                            <option value="dr">Dr</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            First Name <span className="text-danger">*</span>
                          </label>
                          <Input
                            placeholder="John"
                            type="text"
                            value={name.f}
                            onChange={(e) => Name({ ...name, f: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <Input
                            placeholder="Doe"
                            type="text"
                            value={name.l}
                            onChange={(e) => Name({ ...name, l: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <label htmlFor="exampleInputEmail1">Last Name</label> */}
                    {/* <InputGroup>
                      <Input
                        placeholder="Doe"
                        type="text"
                        value={name.l}
                        onChange={(e) => Name({ ...name, l: e.target.value })}
                      />
                    </InputGroup> */}
                  </FormGroup>

                  <FormGroup>
                    <h5>Phone Number</h5>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Country <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            required
                            value={phone.cn}
                            onChange={(e) =>
                              Phone({ ...phone, cn: e.target.value })
                            }
                          >
                            <option value="">Select</option>
                            <option value="44">UK (+44)</option>
                            <option value="880">Bangladesh (+880)</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="form-group">
                          <label>
                            Number <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="79 0000 0000"
                            value={phone.n}
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              Phone({ ...phone, n: e.target.value });
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <h5>Address</h5>
                    <div className="row">
                      <div className="col-sm-4">
                        <label>
                          Address line 1 <span className="text-danger">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Line1"
                            value={addr.l1}
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              Address({ ...addr, l1: e.target.value });
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>Address line 2</label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Line2"
                            value={addr.l2}
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              Address({ ...addr, l2: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>
                          City <span className="text-danger">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="City"
                            value={addr.c}
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              Address({ ...addr, c: e.target.value });
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Province</label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Province"
                            value={addr.p}
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              Address({ ...addr, p: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>ZIP</label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Postal Code"
                            value={addr.pc}
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              Address({ ...addr, pc: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label>
                          Country <span className="text-danger">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Country"
                            value={addr.cn}
                            onChange={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              Address({ ...addr, cn: e.target.value });
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="col-sm-12 m-b-20">
                    <button
                      className="btn btn btn-primary btn-rounded"
                      onClick={(e) => {
                        setEdit(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn btn-primary btn-rounded"
                      onClick={UpdateInfo}
                    >
                      Update
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        ) : (
          <Col xs="12">
            <div className="card p-4">
              <div>
                <p>
                  {/* Name: <span>{props.ShowName(name)}</span> */}
                  Name: <span>{nameShow}</span>
                </p>
                <p>Email : {emailShow}</p>
                {/* <p>Phone : {props.ShowPhone(phone)}</p> */}
                <p>Phone : {phoneShow}</p>
                {/* <p>Address : {props.ShowAdr(addr)}</p> */}
                <p>Address : {addrShow}</p>
              </div>
              <div className="col-sm-8 col-9 m-b-20 p-0">
                <button
                  className="btn btn btn-primary btn-rounded"
                  onClick={(e) => {
                    setEdit(true);
                  }}
                >
                  <i className="fa fa-edit" /> Edit Profile
                </button>
              </div>
            </div>
          </Col>
        )}
        {/*<div className="card p-4">
                    <div className="row">
                    <div className="col-sm-10">
                        <h4>
                        <i className="fa fa-credit-card" /> Visa{" "}
                        <span className="pull-right">
                            1254 9865 9875 4478
                            <i className="fa fa-trash text-danger" />
                        </span>
                        </h4>
                        <h4>
                        <i className="fa fa-credit-card" /> Paypal{" "}
                        <span className="pull-right">
                            1254 9865 9875 4478{" "}
                            <i className="fa fa-trash text-danger" />
                        </span>
                        </h4>
                        <h4>
                        <i className="fa fa-credit-card" /> Mastercard{" "}
                        <span className="pull-right">
                            1254 9865 9875 4478
                            <i className="fa fa-trash text-danger" />
                        </span>
                        </h4>
                    </div>
                    </div>
                </div>*/}
      </Row>
    </Col>
  );
}; // ProfileInfo

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(ProfileInfo);

/**
 */
const ViewEdit = ( props ) =>
{
  return (
    null
  )
} // ViewEdit

/**
 */
const ViewPresent = ( props ) =>
{
  return (
    null
  )
} // ViewPresent

