/**
 *	@copyright	KARD.
 */

import * as Cfg from './cfg'
import * as AWS from '../../api/AWS'
import {Core, Lambda} from "../../api/AWS";


/**
 */
export const EmailSubscribe = (p ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_subs = {
                actType: 'comm',
                act: 'comm:email-sub:subscribe',
                c_id:  gs().__cfg.oId,

                data: {
                    o_id: gs().__cfg.oId,
                    ...p,
                },
            }

            console.log( 'actions/comm: Comm_EmailSubscribe: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, lambda_name )


            console.log( 'actions/comm: Comm_EmailSubscribe: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_EmailSubscribe: err: ', err )
            return Promise.reject( err )
        }
    }
}   // Comm_EmailSubscribe

/**
 */
export const EmailUnsubscribe = (p ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_subs = {
                actType: 'comm',
                act: 'comm:email-sub:unsubscribe',
                c_id:  gs().__cfg.oId,

                data: {
                    o_id: gs().__cfg.oId,
                    ...p,
                },
            }

            console.log( 'actions/comm: Comm_EmailSubscribe: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, lambda_name )


            console.log( 'actions/comm: Comm_EmailSubscribe: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_EmailSubscribe: err: ', err )
            return Promise.reject( err )
        }
    }
}   // Comm_EmailSubscribe
/**
 */
export const GetSubscriptionList = ( email ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_subs = {
                actType: 'comm',
                act: 'comm:email-sub:get-list-by-user',
                c_id:  gs().__cfg.oId,

                data: {
                    email,
                },
            }

            console.log( 'actions/comm: GetSubscriptionList: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, lambda_name )


            console.log( 'actions/comm: GetSubscriptionList: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: GetSubscriptionList: err: ', err )
            return Promise.reject( err )
        }
    }
}   // GetSubscriptionList

