/**
 *	@copyright	KARD..
 */

// import * as Cfg from '../../_cfg'
// import CfgCore from '../../_cfg/core'
// import {Lambda as CfgLambda} from '../../_cfg/back'

import * as AWS from '../../api/AWS'

// import {Lambda, Core} from '../../api/AWS'

// import { Time } from 'api/Utils'

import * as Cfg from './cfg'

/**
 */
export const About_Volunteer = ( {name, email, phone, message} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const lambda_name = d( Cfg.Lambda('unauth') )

            // console.log( 'actions/crowd: Crowd_DonationList: lambda_name: ', lambda_name )

            const p_vol = {
                actType: 'comm',
                act: 'comm:contact',
                c_id: gs().__cfg.oId,

                data: {
                    name, phone, email, message,
                    type: 'volunteer:req',
                }
            }   // p_list

            // console.log( 'actions/crowd: Crowd_DonationList: p_list: ', p_list )

            const resp_vol = await aws_lambda.Invoke( p_vol, lambda_name )

            if( resp_vol )
            {
                //
            }

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'rdx.About_Volunteer: err: ', err )

            return Promise.reject( err )
        }
    }   // ...
}   // About_Volunteer


