import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

// core components

function StartCampaign(props) {
  return (
    <>
      <div className="section start-fundraiser">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">Ready to start fundraising ?</h2>
              <button
                className="btn btn-info btn-lg "
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  // navigate("/crowd/campaign/create");
                  if ("init" === props.__auth.status) {
                    //
                  } else if ("loggedin" === props.__auth.status) {
                    navigate("/crowd/campaign/create/basics");
                    // navigate("/crowd/campaign/create");
                  } else {
                    navigate("/auth/register");
                  }
                }}
              >
                Start A Campaign
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

// export default StartCampaign;

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(StartCampaign);
