import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row } from "reactstrap";
// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Programs from "./program";
import CarouselSection from "./carousel";
import CharityFooter from "components/Footers/CharityFooter.js";

function Campaign() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "60vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          >
            <div className="content-center">
              <Container>
                <h2 className="title text-white">All Campaigns</h2>
                <p>Home / Campaigns</p>
                <Row>
                  <div className="col">
                    <select
                      id="inputState"
                      className="form-select form-control"
                    >
                      <option selected>Filter By Search</option>
                      <option>Water</option>
                      <option>School</option>
                      <option>Enviorments</option>
                    </select>
                  </div>
                  <div className="col">
                    <select
                      id="inputState"
                      className="form-select form-control"
                    >
                      <option selected>Filter By Category</option>
                      <option>Water</option>
                      <option>School</option>
                      <option>Enviorments</option>
                    </select>
                  </div>
                  <div className="col">
                    <select
                      id="inputState"
                      className="form-select form-control"
                    >
                      <option selected>Filter By Date</option>
                      <option>Water</option>
                      <option>School</option>
                      <option>Enviorments</option>
                    </select>
                  </div>
                  <div className="col">
                    <select
                      id="inputState"
                      className="form-select form-control"
                    >
                      <option selected>Filter Campaign</option>
                      <option>Water</option>
                      <option>School</option>
                      <option>Enviorments</option>
                    </select>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <div className="section">
          <CarouselSection />
        </div>
        <div className="section">
          <Programs />
        </div>
        <CharityFooter />
      </div>
    </>
  );
}

export default Campaign;
