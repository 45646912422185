import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function SingleNews5() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/news/n5.jpg").default + ")",
            }}
          ></div>
        </div>

        <div className="section section-work">
          <Container>
            <Row>
              <Col sm="12">
                <h3 style={{ lineHeight: "35px" }}>
                  O. B. LULU-BRIGGS FOUNDATION KICKS OFF YEARLONG 20TH
                  ANNIVERSARY CELEBRATIONS
                </h3>
                <img
                  alt="..."
                  className="rounded img-fluid my-4"
                  src={require("assets/img/news/allnews/n3.jpg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      The O. B. Lulu-Briggs Foundation will continue to fulfil
                      its mission of implementing sustainable interventions that
                      enhance the quality of life for under-served persons,
                      Chairman of the Foundation, Dr Mrs. Seinye Lulu-Briggs has
                      said.
                    </a>
                    <br />
                    <br />
                    <a>
                      She made the pledge during a special thanksgiving service
                      held in Port Harcourt on Tuesday 21 September 2021 to
                      commemorate and kick-off what will be a yearlong 20th
                      anniversary celebration.
                    </a>
                    <br />
                    <br />
                    <a>
                      The well-attended event also coincided with her 63rd
                      birthday and the 13th anniversary of the Chapel of God
                      International Worship Center over which she presides as
                      Senior Pastor.
                    </a>
                    <br />
                    <br />
                    <a>
                      “This is 63 years of God’s faithfulness in my life; 20
                      years of God’s grace in the life of the O. B. Lulu-Briggs
                      Foundation as we continue to serve mankind and 13 years of
                      God saving lives through the Chapel of God.
                    </a>
                    <br />
                    <br />
                    <a>
                      “Two decades of consistent and impactful interventions in
                      the lives of under-served persons, across communities in
                      Nigeria’s Niger Delta is a milestone accomplishment. We
                      feel honoured by God to have been able to bring hope to
                      thousands of families and many communities, we otherwise
                      may not have reached,” Mrs. Lulu-Briggs said.
                    </a>
                    <br />
                    <br />
                    <a>
                      On the impact of the Foundation’s work, Mrs. Lulu-Briggs
                      said that “since 2005, through 34 Free Medical Missions it
                      organized, the O. B. Lulu-Briggs Foundation has provided
                      high quality health services, including surgeries, to
                      132,591 men, women and children in rural and semi-urban
                      Niger Delta communities.” Inmates of the Correctional
                      Facilities in Port Harcourt are among beneficiaries of the
                      Foundation’s Free Medical Missions which, she said, aligns
                      with the UN Sustainable Development Goal 3 that aims to
                      ensure healthy lives and promote well-being for all at all
                      age.
                    </a>
                    <br />
                    <br />
                    <a>
                      Through its Access to Clean Water and Sanitation
                      programme, according to Mrs. O.B. Lulu-Briggs, the
                      Foundation has taken potable water to 33 communities
                      across the Niger Delta region. It has also built and
                      donated toilet and sanitary facilities to enhance public
                      health and wellbeing.
                    </a>
                    <br />
                    <br />
                    <a>
                      “So far, we have provided 10,450 households in 25
                      communities of the Niger Delta with access to potable
                      water,” she said.
                    </a>
                    <br />
                    <br />
                    <a>
                      “In the last 20 years, through our interventions in the
                      education sector, we have empowered 1,316 persons with
                      scholarships for formal education and training programs
                      from Nursery to tertiary levels, including the Nigeria Law
                      School. We have also provided funding to 30 teachers and
                      trainers and built an Information Technology Centre for
                      learning.”
                    </a>
                    <br />
                    <br />
                    <a>
                      Visibly elated, Mrs. Lulu-Briggs expressed gratitude to
                      all who have contributed to the success of the Foundation
                      in the last twenty years.
                    </a>
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      “We are grateful and humbled that, today, we can look back
                      at 20 years of programming and appreciate the impact that
                      we have achieved in touching and improving the health,
                      well-being, and livelihoods of people in several
                      communities on account of the boundless generosity of my
                      husband of blessed memory, High Chief (Dr) O. B.
                      Lulu-Briggs, OON, DCF, DSSRS, our grand benefactor. We
                      trust that God will continue to enable this Foundation to
                      work for humanity.
                    </a>
                    <br />
                    <br />
                    <a>
                      “We wish to acknowledge and to thank, from the bottom of
                      our hearts, all the people and entities that have
                      supported our work over the years. Your support has been
                      crucial to our success! Let me assure you that all that we
                      have accomplished in the last 20 years is nothing compared
                      to what we will achieve in the next twenty years.”
                    </a>
                    <br />
                    <br />
                    <a>
                      The Presiding Pastor of the Thanksgiving Service, Pastor
                      Dubem Okwuosa while commending Dr. Seinye Lulu-Briggs for
                      her vision, asked the O.B. Lulu-Briggs Foundation team to
                      continue to draw its strength from God.
                    </a>
                    <br />
                    <br />
                    <a>
                      On her part, Hilda Desmond-Ihekiare, Chairperson of the
                      Rivers State Branch of the African Women Lawyers
                      Association (AWLA), one of the Foundation’s institutional
                      partners said the O.B. Lulu-Briggs Foundation has offered
                      hope to many widows in the Niger Delta region through
                      their partnership which provides free legal services to
                      widows in Rivers State.
                    </a>
                    <br />
                    <br />
                    <a>
                      According to a statement released by the Media Lead of the
                      O. B. Lulu-Briggs Foundation, Barr. Oraye St. Franklyn,
                      other activities lined up as part of the yearlong 20th
                      anniversary celebration are a Free Eye Clinic for the 23rd
                      of September 2021, a Free Legal Clinic to hold on the 24th
                      of November 2021 among other programmes to be unveiled in
                      the course of the celebrations. According to Barr. St.
                      Franklyn, an anniversary lecture and gala night are
                      scheduled to hold in February 2022, which would afford the
                      foundation an opportunity to acknowledge and appreciate
                      its partners and volunteers.
                    </a>
                    <br />
                    <br />
                    <a>
                      The O. B Lulu-Briggs Foundation was founded in 2001 by
                      Mrs. Seinye O. B. Lulu-Briggs in the name of her beloved
                      husband, High Chief (Dr.) O. B. Lulu-Briggs, OON, DCF,
                      DSSRS, (1930-2018), to honour, celebrate and
                      institutionalise his legendary giving.
                    </a>
                    <br />
                    <br />
                    <a>
                      The Foundation’s mission is to implement sustainable
                      interventions through these five programmes: Care for
                      Life, also popularly called, Care for the Elderly; Free
                      Medical Missions; Access to Clean Water and Sanitation;
                      Education and Micro-credit and Entrepreneurship.
                    </a>
                    <br />
                    <br />
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleNews5;
