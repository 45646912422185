import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function PortableWater() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/water/water-2.jpg").default +
                ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white ">
                Water and Sanitation - WASH
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext/1642076954/1642077140061");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p>
                  The OB Lulu-Briggs Foundation is now turning its attention to
                  Water, Sanitation and Hygiene (WASH), an important area of
                  development work which seeks to ensure equal access to clean,
                  safe drinking water and sanitation and hygiene facilities
                  which plays a crucial role in the overall health and
                  well-being of any society. Many water-borne diseases and
                  illnesses spread in impoverished areas due to unsafe drinking
                  water and the lack of widespread hygiene practices and
                  behaviours. WASH is an important concept – one which must be
                  addressed comprehensively and holistically. In 2020, according
                  to statistics, only 22% of the population in Nigeria were
                  using a safely managed drinking water service, and 33% had a
                  hand-washing facility with soap and water available at home.
                </p>
                <p>
                  Currently, Nigeria is not on track toward meeting SDG 6 which
                  relates to WASH, and is in fact a long way off, unless
                  concerted efforts are made to provide WASH facilities and
                  improve access to clean, safe water and hygiene facilities.
                </p>
                <p>
                  In trying to respond to some of these concerns, our Foundation
                  has constructed two blocks of toilets and potable water for
                  the Port Harcourt Prisons which houses approximately 4000
                  inmates. In addition, we have installed two septic tanks at
                  Degema Prison which houses about 250 prison inmates. Alongside
                  this, the Foundation also utilises the services of 86 youths
                  to keep Abonnema clean through daily sweeping and cleaning of
                  streets. 36 other youths also ensure the Abonnema cemetery is
                  kept clean and beautified with flowers and plants.
                </p>

                <img
                  alt="..."
                  className="rounded img-fluid center-img"
                  src={require("assets/img/works/water/water-4.jpg").default}
                ></img>
                <p className="mt-5">
                  Our Access to Potable Water and Sanitation Programme ensures
                  that rural communities have access to clean, safe and quality
                  drinking water. This is a strategy to combat diseases such as
                  cholera, dysentery, intestinal and other water-borne diseases.
                  The programme targets communities that lack access to safe
                  drinking water by providing them water treatment packages and
                  bore holes. More than 10,000 households in 32 communities in
                  Rivers State have gained access to potable water through our
                  work. This WASH initiative aligns with SDG 6, to ensure
                  availability and sustainable management of water and
                  sanitation for all. We hope to replicate this WASH initiative
                  in rural and remote areas of the Global South where WASH
                  remains a concern.
                </p>
                {/* <a
                  className="btn btn-lg text-white pt-3 cmpBtn"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/donation/ext/1642076954/1642077140061");
                  }}
                >
                  Donate
                </a> */}
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default PortableWater;
