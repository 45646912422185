import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { navigate } from "@reach/router";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function P404() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section-campaign">
          <Container>
            <Row>
              <Col sm="12">
                <div className="section section-campaign d-flex justify-content-center text-center">
                  <div className="error-template">
                    <h1>Oops!</h1>
                    <h2>404 Not Found</h2>
                    <div className="error-details">
                      Sorry, Requested page not found!
                    </div>
                    <div className="error-actions">
                      <a
                        // href=""
                        className="btn btn-primary btn-lg"
                        onClick={ e => {
                          e.preventDefault()
                          e.stopPropagation()

                          navigate( '/' )
                        } }
                      >
                        <span className="glyphicon glyphicon-home"></span>
                        Take Me Home
                      </a>
                      {/* <a href="" class="btn btn-default btn-lg">
                        <span class="glyphicon glyphicon-envelope"></span>
                        Contact Support
                      </a> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default P404;
