import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function HealthAwareness() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/free-medical/medical-1.JPG").default +
                ")",
            }}
          ></div>

          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white ">
                Health Awarness Campaigns
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p>
                  Uterine Fibroids are noncancerous growths on the uterus.
                  Fibroids can cause bloating, discomfort, pain, heavy menstrual
                  bleeding and infertility in some women, but others experience
                  no adverse symptoms. Women’s health advocates have long argued
                  for greater public awareness about fibroids in order to
                  counter cultural myths and limited understanding about the
                  factors that promote its prevalence with a view to promoting
                  prevention through actions that can minimize the suffering and
                  anguish it causes. Also, to encourage more research and the
                  development of better treatment options.
                </p>
                <p>
                  Presently, the surgical removal of the fibroids, a myomectomy,
                  is one of the most frequent treatments available to Nigerian
                  women. Myomectomies are expensive and beyond the financial
                  capacities of many women and their families. This is one of
                  the reasons the Foundation offered 100 free fibroid surgeries
                  as part of its campaign.
                </p>
                <img
                  alt="..."
                  className="rounded img-fluid img-raised"
                  src={
                    require("assets/img/works/free-medical/medical-2.JPG")
                      .default
                  }
                ></img>
                <p className="mt-5">
                  As part of our work to promote health and wellbeing, we have
                  carried out health awareness campaigns with a view to breaking
                  myths about common diseases and to encourage people to look
                  after their health.
                  <br />
                  <br />
                  OIn 2019, to mark our 18th anniversary, we launched a
                  campaign, ‘Raising Awareness About Uterine Fibroids- Get
                  Informed and Take Action.’ The highlight of the campaign was
                  100 free fibroid surgeries, myomectomies, to women.
                  <br />
                  <br />
                  Over 1,000 women in urgent need of fibroid surgeries but
                  lacking finances to cover the attendant costs attended our
                  fibroid awareness event on September 21, 2019; 452 of the
                  women registered to be considered for the surgeries. The
                  surgeries are underway at the University of Port Harcourt
                  Teaching Hospital and the Rivers State University Teaching
                  Hospital in Port Harcourt, Rivers State. To-date 75 women have
                  undergone surgery.
                  <br />
                  <br />
                  Health awareness campaigns that have been carried out by the
                  Foundation include a campaign on Kidney Disease through
                  campaigns tagged
                  <br />
                  <br />
                </p>

                <img
                  alt="..."
                  className="rounded img-fluid img-raised mt-5 mb-5"
                  src={
                    require("assets/img/works/free-medical/medical-3.JPG")
                      .default
                  }
                ></img>

                <ul className="list-group">
                  <li className="list-group-item list-group-item-info font-weight-bold">
                    “Your Kidney and You” in 2018
                  </li>
                  <li className="list-group-item list-group-item-info font-weight-bold">
                    “The Care You Need” campaign in 2017 during which people
                    learned about and were screened for Diabetes.
                  </li>
                  <li className="list-group-item list-group-item-info font-weight-bold">
                    Free PSA screening for men as part of its Prostate Cancer
                    awareness campaign
                  </li>
                  <li className="list-group-item list-group-item-info font-weight-bold">
                    In 2010, a health awareness programme focused on Parkinson’s
                    Disease.
                  </li>
                </ul>
                {/* <a
                  className="btn btn-lg text-white pt-3 cmpBtn"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/donation/ext");
                  }}
                >
                  Donate
                </a> */}
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default HealthAwareness;
