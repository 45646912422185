import React from "react";
import "../home/style.css";
// reactstrap components

import {
  Button,
  Form,
  Input,
  FormGroup,
  Container,
  Col,
  Row,
  InputGroup,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Contact() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>

          <div className="content-center">
            <Container>
              <h2 href="#" className="title text-white p-0">
                Contact Us
              </h2>
            </Container>
          </div>
        </div>
        <div className="section section-campaign">
          <Container>
            <Row>
              <Col md="4" xs="12" className="d-flex text-center">
                <div className="py-4">
                  <i
                    className="fa fa-map text-info"
                    style={{ fontSize: "2.5rem" }}
                  />
                  <h2 className="title">Address</h2>
                  <p>
                    17/23 Igbodo Street Old G.R.A, Port-Harcourt, Rivers State.
                  </p>
                </div>
              </Col>
              <Col md="4" xs="12" className="d-flex text-center">
                <div className="py-4">
                  <i
                    className="fa fa-phone text-info"
                    style={{ fontSize: "2.5rem" }}
                  />
                  <h2 className="title">Phone</h2>
                  <p>+23484847781 +234706 855 5089</p>
                </div>
              </Col>
              <Col md="4" xs="12" className="d-flex text-center">
                <div className="py-4">
                  <i
                    className="fa fa-envelope fa-lg text-info"
                    style={{ fontSize: "2.5rem" }}
                  />
                  <h2 className="title">Email</h2>
                  <p>info@oblulubriggsfoundation.org</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <h3 className=""></h3>
                <div
                  id="map-container-google-2"
                  className="z-depth-1-half map-container"
                  style={{ marginTop: 30 }}
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1987.9386068984968!2d7.003583!3d4.791111!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1069cea104958851%3A0xed5d5c7f928c3f7c!2sIgbodo%20St%2C%20Woji%20500101%2C%20Port%20Harcourt%2C%20Nigeria!5e0!3m2!1sen!2sus!4v1637582179016!5m2!1sen!2sus"
                    style={{ width: "100%", height: "400px", borderRadius: 12 }}
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
              {/* <Col sm="6">
                <Form>
                  <FormGroup>
                    <p htmlFor="name">Your Name</p>
                    <Input
                      aria-describedby="emailHelp"
                      id="name"
                      placeholder="Enter Name"
                      type="text"
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <p htmlFor="exampleInputEmail1">Email address</p>
                    <Input
                      aria-describedby="emailHelp"
                      id="exampleInputEmail1"
                      placeholder="Enter email"
                      type="email"
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <p htmlFor="phone">Phone Number</p>
                    <Input
                      aria-describedby="emailHelp"
                      id="phone"
                      placeholder="Enter Phone"
                      type="text"
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <p htmlFor="exampleFormControlTextarea1">Your Message</p>
                    <Input
                      id="exampleFormControlTextarea1"
                      rows="3"
                      type="textarea"
                    ></Input>
                  </FormGroup>

                  <Button color="info" type="submit" style={{ width: "100%" }}>
                    SEND
                  </Button>
                </Form>
              </Col> */}
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default Contact;
