import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Acheivements() {
  let pageHeader = React.createRef();

  return (
    <>
      <div className="section section-acheived">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto px-5 text-center" md="12">
              {/* <h4 style={{ color: "#45aae5" }}>Know more about</h4> */}
              <h2 className="bd-title" style={{ paddingTop: "0px" }}>
                Our Impact
              </h2>
              <p className="mb-5">
                {/* Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, sed quia non numquam eius modi
                tempora incidunt ut labore et dolore magnam aliquam. */}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Row>
                <Col
                  className="ml-auto mr-auto text-center ourAchie"
                  md="4"
                  lg="2"
                >
                  <i
                    className="fa fa-female fa-lg icon-danger icon"
                    style={{
                      fontSize: "50px",
                      marginBottom: "15px",
                      color: "#256ba9",
                    }}
                  />
                  <h4 style={{ fontSize: "22px" }}>500+</h4>
                  <h4>Elders cared for</h4>
                </Col>
                <Col
                  className="ml-auto mr-auto text-center ourAchie"
                  md="4"
                  lg="2"
                >
                  <i
                    className="fa fa-heartbeat fa-lg icon-danger"
                    style={{
                      fontSize: "50px",
                      marginBottom: "15px",
                      color: "#ff3636",
                    }}
                  />
                  <h4 style={{ fontSize: "22px" }}>132668+</h4>
                  <h4>Free medical interventions </h4>
                </Col>
                <Col
                  className="ml-auto mr-auto text-center ourAchie"
                  md="4"
                  lg="2"
                >
                  <i
                    className="fa fa-book fa-lg icon-danger"
                    style={{
                      fontSize: "50px",
                      marginBottom: "15px",
                      color: "#282525",
                    }}
                  />
                  <h4 style={{ fontSize: "22px" }}>1326+</h4>
                  <h4>Education sponsorships </h4>
                </Col>
                <Col
                  className="ml-auto mr-auto text-center ourAchie"
                  md="4"
                  lg="2"
                >
                  <i
                    className="fa fa-comments-dollar fa-lg icon-danger"
                    style={{
                      fontSize: "50px",
                      marginBottom: "15px",
                      color: "#0a830a",
                    }}
                  />
                  <h4 style={{ fontSize: "22px" }}>1075+</h4>
                  <h4>Microfinance loans and free training</h4>
                </Col>
                <Col
                  className="ml-auto mr-auto text-center ourAchie"
                  md="4"
                  lg="2"
                >
                  <i
                    className="fa fa-tint fa-lg icon-danger"
                    style={{
                      fontSize: "50px",
                      marginBottom: "15px",
                      color: "#989fa7",
                    }}
                  />
                  <h4 style={{ fontSize: "22px" }}>32+</h4>
                  <h4>Water & Sanitation</h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Acheivements;
