import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function SingleNews2() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/news/n2.jpeg").default + ")",
            }}
          ></div>

          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white ">
                News / JUSTICE VITAL TO BUILDING SOCIETIES
              </h3>
            </Container>
          </div>
        </div>

        <div className="section section-work">
          <Container>
            <Row>
              <Col sm="12">
                <h3 style={{ lineHeight: "35px" }}>
                  AS FOUNDATION HOLDS FREE LAW CLINIC, SEINYE LULU-BRIGGS SAYS
                  ACCESS TO JUSTICE VITAL TO BUILDING SOCIETIES
                </h3>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Access to Justice is vital for building inclusive societies
                    in which the voices of vulnerable persons are heard and
                    their rights to challenge discrimination or oppression are
                    upheld, Chairman of the Board of Trustees of the O. B.
                    Lulu-Briggs Foundation, Dr. Seinye O. B. Lulu-Briggs, has
                    said.
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    She stated this in Port Harcourt, the Rivers State capital,
                    at the opening of a free law clinic for members of society
                    organized by the O. B. Lulu-Briggs Foundation. Mrs.
                    Lulu-Briggs said the Law Clinic was organized in partnership
                    with legal experts and law students to deepen understanding
                    of the concept of human rights, and the rule of law as they
                    affected peculiar social issues in society.
                  </div>
                </Col>

                <img
                  alt="..."
                  className="rounded img-fluid  my-4"
                  src={require("assets/img/news/allnews/n2.1.jpeg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    She said it was regrettable that a lot of vulnerable persons
                    suffer in silence on account of either a lack of funding to
                    consult lawyers, lack of understanding of their rights or a
                    misconception of the law in general.
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    “Since 2009, in line with our commitment to promoting access
                    to justice, inclusivity and the rule of law, we have
                    invested in legal education and, by God’s grace, have funded
                    the training of 691 Nigerian lawyers to date,” she said.
                    “While that has its benefit to society, we believe promoting
                    civil engagement and civic education through this Law Clinic
                    can also go a long way in creating awareness about the law
                    and enhancing access to justice, especially for vulnerable
                    persons, in line with goal 16 of the United Nation’s
                    Sustainable Development Goals.”
                  </div>
                </Col>
                <img
                  alt="..."
                  className="rounded img-fluid  my-3"
                  src={require("assets/img/news/allnews/n2.3.jpeg").default}
                ></img>

                <p className="mt-5">
                  The Free Law Clinic, which drew attendance from the public, is
                  part of year-long activities marking the 20th anniversary of
                  the O. B. Lulu-Briggs Foundation, founded by Dr. Seinye O. B.
                  Lulu-Briggs to honor and celebrate the legendary philanthropy
                  of her husband, the late High Chief (Dr) O. B. Lulu-Briggs,
                  OON, DCF, DSSRS.
                </p>
                <br />
                <br />

                <img
                  alt="..."
                  className="rounded img-fluids my-3"
                  src={require("assets/img/news/allnews/n2.jpeg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Also speaking, Hon. Nimi Walson-Jack, the Coordinator of the
                    Free Law Clinic, said free legal aid recognizes the fact
                    that Law brings about harmony in society.
                    <br />
                    <br />
                    “Contrary to what some people think when they talk about the
                    full wrath of the law the law is not necessarily for
                    punishment,” he said. “The law brings about social cohesion
                    and harmony. Now, if someone is dissatisfied with a
                    situation and doesn’t know what the legal position is, such
                    a person can take the laws into their hands and then you
                    would have crime, criminality and disorder in society.
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    “But through this programme, we give you advice that helps
                    to settle you and your position, so you know where you stand
                    with the law. We also give you advice that is a remedy. So,
                    perhaps all you need to do is to simply write a letter,
                    which may not necessarily be written by a lawyer. But if it
                    has to be written by a lawyer, just the same way lawyers
                    have volunteered to be here for pro-bono service, there are
                    still lawyers in law firms who do pro bono matters and we
                    will still refer the needful cases to them to get the matter
                    done.”
                    <br />
                    <br />
                    Beneficiaries of the Free Law Clinic expressed their
                    gratitude to the Foundation for the thoughtful intervention
                    in bringing awareness on human rights and the law closer to
                    the people in a practical and convivial setting.
                  </div>
                </Col>
                <img
                  alt="..."
                  className="rounded img-fluid  my-3"
                  src={require("assets/img/news/allnews/n2.2.jpeg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    The O. B. Lulu-Briggs Foundation has in the last two decades
                    consistently delivered impactful interventions in the lives
                    of under-served persons, across communities in Nigeria.
                    Through the 34 Free Medical Missions it organized, the
                    Foundation has provided high-quality health services,
                    including surgeries, to 132,591 men, women and children in
                    rural and semi-urban Niger Delta communities.
                    <br />
                    <br />
                    Inmates of the Correctional Facilities in Port Harcourt are
                    among beneficiaries of the Foundation’s Free Medical
                    Missions, which align with the UN Sustainable Development
                    Goal 3 that aims to ensure healthy lives and promote
                    well-being for all
                  </div>
                </Col>

                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Through its Access to Clean Water and Sanitation programme,
                    the Foundation has taken potable water to 33 communities
                    across the Niger Delta region. It has also built and donated
                    toilet and sanitary facilities to enhance public health and
                    wellbeing. A total of 10,450 households in 25 communities of
                    the Niger Delta have had access to potable water courtesy of
                    the Foundation.
                    <br />
                    <br />
                    The Foundation has also, through its Education and
                    Scholarships programme, empowered 1,316 persons with
                    scholarships for formal education and training programs from
                    Nursery to tertiary levels, including at the Nigeria Law
                    School. It has also provided funding to 30 teachers and
                    trainers and built an Information Technology Centre for
                    learning.
                  </div>
                </Col>
                <img
                  alt="..."
                  className="rounded img-fluid  my-3"
                  src={require("assets/img/news/allnews/n2.2.jpeg").default}
                ></img>

                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Executive Assistant to the Foundation’s Chairman, Barr.
                    Oraye St. Franklyn, said in a statement that an anniversary
                    lecture, as well as gala night, are scheduled to hold in
                    February 2022. He said this would afford the Foundation an
                    opportunity to acknowledge and appreciate its partners and
                    volunteers.
                    <br />
                    <br />
                    The mission of the O. B Lulu-Briggs Foundation, founded in
                    2001, is to implement sustainable interventions through
                    these five programmes: Care for Life, also popularly called,
                    Care for the Elderly; Free Medical Missions; Access to Clean
                    Water and Sanitation; Education; and Micro-credit and
                    Entrepreneurship
                  </div>
                </Col>

                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    The mission of the O. B Lulu-Briggs Foundation, founded in
                    2001, is to implement sustainable interventions through
                    these five programmes: Care for Life, also popularly called,
                    Care for the Elderly; Free Medical Missions; Access to Clean
                    Water and Sanitation; Education; and Micro-credit and
                    Entrepreneurship
                  </div>
                </Col>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleNews2;
