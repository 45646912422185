import React, { useEffect, useRef, useState } from "react";
import "../home/style.css";
import Autocomplete from "react-google-autocomplete";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

// reactstrap components
import {
  Button,
  Alert,
  Container,
  Row,
  Col,
  Form,
  Modal,
  ModalBody,
} from "reactstrap";

import Loader from "react-js-loader";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "rdx/actions";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

// import ListProj from "./list_proj";
// import ListCamp from "./list_camp";
// import { async } from "q";
// import ListCamp from './list_camp'

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CardForm from "api/pay/cardForm";

// import * as Cfg from '_cfg'

import AuiModal from "../../aui/modal";
import ListPack from "./list_pack";
import * as Cfg from "../../_cfg";
import {
  Campaign_Get,
  Donation_Notify,
  DonationNew,
  Donor_New,
  GetCrowdCampaignDetails,
  PaymentReceived,
  Project_Get,
} from "rdx/actions";

import { navigate } from "@reach/router";
import {GetStripePK} from "../../rdx/actions/charity";
// import { json } from "stream/consumers";

/**
 */
function CrowdDonation(props) {
  const [isBusy, IsBusy] = React.useState(false);

  const [modalLive, setModalLive] = React.useState(false);
  // console.log(props)
  const dispatch = useDispatch();
  const oId = Cfg.core.o_id;
  const [isProcessing, SetProcessing] = useState(false);
  const [errorMessage, SetErrorMessage] = useState(null);

  const [curProj, CurProj] = React.useState(null);
  const [curCamp, CurCamp] = React.useState(null);
  const [crowdCmp, SetCrowdCmp] = useState(null);
  const [curPck, CurPck] = React.useState({}); // {tn: -1, t: 'Other', a: 0, p: 'one',}
  const [curDonType, CurDonType] = React.useState(null);
  const [phone, Phone] = React.useState({ cn: "44", n: "" });
  const [email, Email] = React.useState("");
  const [name, Name] = React.useState({ t: "", f: "", l: "" });
  const [addr, Addr] = React.useState({
    l1: "",
    l2: "",
    c: "",
    p: "",
    cn: "UK",
    pc: "",
  });
  const [isAnonymous, SetAnonymous] = useState(false);
  const [msg, SetMsg] = useState("");
  const [uId, SetUid] = useState(null);
  const [payment, Payment] = React.useState({ m: "card", s: "not-paid" });
  const [isGiftAid, IsGiftAid] = React.useState(false);
  const [isConsent, IsConsent] = React.useState(false);
  // const [isConsentProj, IsConsentProj] = React.useState(false);
  // const [isConsentCharity, IsConsentCharity] = React.useState(false);
  const [showPackageList, SetShowPackageList] = useState(false);
  const [payObj, SetPayObj] = useState(null);

  const [authCheck, SetAuthCheck] = useState(true);
  const [stripePromise, setStripePromise] = React.useState(null);


  // const { uid } = props;
  const uid = decodeURIComponent(props.uid);

  let cardRef = useRef();
  React.useEffect(() => {
    // console.log("props.__Prof.profInfo", props.__Prof);

    Name(
        props.__Prof.profInfo && props.__Prof.profInfo.n
            ? props.__Prof.profInfo.n
            : { t: "", f: "", l: "" }
    );
    Email(
        props.__Prof.profInfo && props.__Prof.profInfo.e
            ? props.__Prof.profInfo.e
            : ""
    );
    Addr(
        props.__Prof.profInfo && props.__Prof.profInfo.a
            ? props.__Prof.profInfo.a
            : {
              l1: "",
              l2: "",
              c: "",
              p: "",
              cn: "UK",
              pc: "",
            }
    );

    // if( props.__Prof.profInfo && props.__Prof.profInfo.p && props.__Prof.profInfo && props.__Prof.profInfo.p.cn && props.__Prof.profInfo && props.__Prof.profInfo.p.cn.length )
    if (
        props.__Prof?.profInfo?.p?.cn?.length &&
        props.__Prof?.profInfo?.p?.n?.length
    ) {
      Phone(props.__Prof.profInfo.p);
    }

    SetUid(props.__Prof?.profInfo?.u);
  }, [props.__Prof.profInfo]);


  const Init = async () => {
    try {
      SetProcessing(true);
      try {
        const pk = await dispatch(GetStripePK())
        if (pk && pk.length > 2) {
          setStripePromise(loadStripe(pk))
        } else {
          setStripePromise(loadStripe(Cfg.stripe.key[Cfg.core.stage]))
        }
      } catch (e) {
        setStripePromise(loadStripe(Cfg.stripe.key[Cfg.core.stage]))
      }
      if (uid) {
        const {project, campaign} = await LoadInfo(uid);
        if (!project) {
          throw new Error("Filed to load project data");
        }
        if (!campaign) {
          throw new Error("Filed to load campaign data");
        }
        CurProj(project);
        CurCamp(campaign);
      }
      try {
        if (props.pck) {
          const dec_pck = decodeURIComponent(props.pck);

          const parse_pck = JSON.parse(dec_pck);

          CurPck(parse_pck);
        } else {
          throw new Error("use default package");
        }
      } catch (err) {
        CurPck({
          tn: -1,
          n: "Other",
          a: 0,
          p: "one",
        });
      }
      SetProcessing(false);

    } catch (e) {
      SetErrorMessage(e?.message || e);
      SetProcessing(false);

    }
  }

  /**
   */
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    Init().then().catch()
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    if ("init" === props.__auth.status) {
      // loader in modal
    }

    if ("unauth" === props.__auth.status && authCheck) {
      setModalLive(true);
    }

    if ("loggedin" === props.__auth.status) {
      //
    }
  }, [props.__auth.status]);


  /**
   */
  async function LoadInfo(uid) {
    try {
      const { success, Item } = await dispatch(
        GetCrowdCampaignDetails({ uid })
      );

      // console.log("cpp",ccp)

      if (!success) {
        throw new Error("Failed to get campaign details");
      }

      SetCrowdCmp(Item);

      const resp_proj = await dispatch(
        Project_Get({
          pr_n: parseInt(Item.p, 10),
        })
      );

      if (!resp_proj.Item) {
        throw new Error("Project Not Found!");
      }
      const resp_camp = await dispatch(
        Campaign_Get({
          pr_n: parseInt(Item.p, 10),
          cp_n: parseInt(Item.c, 10),
        })
      );

      if (!resp_camp.Item) {
        throw new Error("Campaign Not Found!");
      }

      return { project: resp_proj.Item, campaign: resp_camp.Item };
    } catch (e) {
      console.warn("donation/index: LoadInfo:error", e);

      return Promise.reject(e);
    }
  }

  /**
   */
  const OnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Submit().catch((err) => {});
  }; // OnSubmit

  /**
   */
  const Submit = async () => {
    try {
      IsBusy(true);

      const resp = await ProcessDonation();

      console.log("Donation status:", resp);

      const {
        // donationPaymentSuccess,
        // campaignEarn,
        // projectEarn,
        paymentSuccess,
        d_i,
      } = resp;

      if (!paymentSuccess) {
        throw new Error("payment failed");
      }

      try {
        await dispatch(Donation_Notify({ d_id: d_i, email_to: [email] }));
      } catch (err_2) {
        // do nothing
      }

      IsBusy(false);

      // alert("Donation Success");

      // navigate("/crowd");
      navigate("/donation/success");

      return {};
    } catch (err) {
      console.warn("donation/index: Submit: err: ", err);

      IsBusy(false);

      alert("Payment Failed");

      return Promise.reject(err);
    }
  }; // OnSubmit

  /**
   */
  const ProcessDonation = async () => {
    try {
      const donor_p = {
        p_no:
          "loggedin" === props.__auth.status && uId ? uId : phone.cn + phone.n,
        name: name,
        addr: addr,
        phone: phone,
        email: email,
      };

      const donation_p = {
        p_no:
          "loggedin" === props.__auth.status && uId ? uId : phone.cn + phone.n,
        type: "crowd",
        pr_i: curProj.i,
        cp_i: curCamp.i,
        pr_n: curProj.tn,
        cp_n: curCamp.tn,
        uid: crowdCmp.uid,
        id: crowdCmp.id,
        pck: {
          tn: curPck.tn,
          t: curPck.t,
          a: curPck.a,
          p: curPck.p,
        },
        dont_t: curDonType,
        pr_t: curProj.p.t,
        cp_t: curCamp.ci.t,
        paym: payment,
        name: name,
        addr: addr,
        phone: phone,
        email: email,
        c_ga: isGiftAid,
        c_ch: isConsent,
        c_pr: isConsent,
        // c_ch: isConsentProj,
        // c_pr: isConsentCharity,
        notes: [],
        s: "pending",
        isAnon: isAnonymous,
        msg: msg,
      };
      if (curPck.p === "rec" && curPck.p_i) {
        donation_p.pck["p_i"] = curPck.p_i;
      }

      const a = await Promise.all([
        NewDonor(donor_p),
        NewDonation(donation_p),
        NewSubs(),
      ]);
      // console.log(a);
      // const { donor } = a[0];
      const { donation } = a[1];
      if (donation) {
        if (payment.m === "card") {
          const paymentData = await cardRef.current.PayWithCard();
          if (paymentData.payment_success) {
            const resp = await dispatch(
              PaymentReceived(
                donation.d,
                paymentData.paymentObj,
                "received",
                "card"
                // parseInt(curPck.a, 10)
              )
            );
            return { paymentSuccess: true, ...resp, d_i: donation.d };
          }
        }
        return { paymentSuccess: false, d_i: donation.d };
      } else {
        throw new Error("Filed to create donation");
      }
    } catch (err) {
      console.warn("Donations_Internal_New: ProcessDonation: err: ", err);

      return Promise.reject(err);
    }
  }; // ProcessDonation

  /**
   */
  const NewDonation = async (donationParam) => {
    try {
      const resp_dont = await dispatch(DonationNew(donationParam));
      console.log(
        "Donations_Internal_New: NewDonation: resp_dont: ",
        resp_dont
      );

      if (!resp_dont.Item) {
        throw new Error("Filed to create donation.\nPlease try again.");
      }

      return { donation: resp_dont.Item };
    } catch (err) {
      console.warn("Donations_Internal_New: NewDonation: err: ", err);
      return Promise.reject(err);
    }
  }; // NewDonation

  /**
   */
  const NewDonor = async (donor) => {
    try {
      const resp_dont = await dispatch(Donor_New(donor));
      return { donor: resp_dont };
    } catch (err) {
      console.warn("donation/index: NewDonor: err: ", err);
      return Promise.reject(err);
    }
  }; // NewDonor

  /**
   */
  const NewSubs = async () => {
    try {
      const p_no = [phone.cn, phone.n].join("");

      const topc_proj = [props.__auth.orgId, curProj.tn].join("/");
      const topc_org = [props.__auth.orgId].join("/");

      // console.log( 'Donations_Internal_New: NewSubs: topc_proj: ', topc_proj )

      const arr_pr = [
        // isConsentProj && email.length ? NewSubs_Email(email, topc_proj) : null,
        isConsent && email.length ? NewSubs_Email(email, topc_proj) : null,
        // isConsentProj && phone.n.length ? NewSubs_SMS( p_no, topc_proj ) : null,
        // isConsentCharity && email.length ? NewSubs_Email(email, topc_org) : null,
        isConsent && email.length ? NewSubs_Email(email, topc_org) : null,
        // isConsentCharity && phone.n.length ? NewSubs_SMS( p_no, topc_org ) : null,
      ];

      const resp_pr = await Promise.all(arr_pr);

      // console.log( 'Donations_Internal_New: NewSubs: resp_pr: ', resp_pr )

      if (resp_pr) {
        //
      }

      return {};
    } catch (err) {
      console.warn("Donations_Internal_New: NewSubs: err: ", err);

      return { err };
    }
  }; // NewSubs

  /**
   */
  const NewSubs_Email = async (email, topc) => {
    try {
      return await props.Comm_EmailSubscribe(email, topc);
    } catch (err) {
      console.warn("Donations_Internal_New: NewSubs_Email: err: ", err);

      return { err };
    }
  }; // NewSubs_Email
  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="donation" style={{ background: "#fff" }}>
          <Container>
            <Col sm="12" className="text-center my-5 mt-5">
              <h2 className="mt-5" style={{ color: "#026666" }}>
                Process Donation
              </h2>
              <p>
                {/* Lorem To The Power taxpayer and i wish ({'this charity'}) to reclaim
                tax back on all donations i have made with the last 6 years and
                all donations that i make hereafter. */}
              </p>
            </Col>
            <Form onSubmit={OnSubmit}>
              <Col sm="12">
                <div
                  className=""
                  style={{
                    backgroundColor: "#f3f3f3",
                    padding: "16px",
                  }}
                >
                  {/* <div className="row">
                    <div className="col-sm-8">
                      <button
                        className="btn-block btn btn-info"
                        style={{
                          fontWeight: 400,
                          margin: 5,
                          padding: 16,
                          backgroundColor: curPck ? "#026666" : null,
                        }}
                      >
                        {crowdCmp ? crowdCmp.t : "Loading ..."}
                      </button>
                    </div>
                    <div className="col-sm-4">
                      <button
                        className="btn-block btn btn-info"
                        style={{
                          fontWeight: 400,
                          margin: 5,
                          padding: 16,
                          backgroundColor: curPck ? "#026666" : null,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          SetShowPackageList(true);
                        }}
                      >
                        {isProcessing
                          ? "Loading..."
                          : curPck
                          // ? `${curPck.n} - ${"£"}${curPck.a} - ${
                          ? `${"£"}${curPck.a} - ${
                              "rec" === curPck.p ? "Recurring" : "Once"
                            }`
                          : "Select Package"}
                      </button>
                    </div>
                  </div> */}
                  <div className="row mt-3">
                    {/* <div className="col-sm-5">
                      <div className="form-group">
                        <label>Select Donation Type</label>
                        <select
                          className="form-control"
                          required
                          onChange={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            CurDonType(event.target.value);
                          }}
                          // disabled
                        >
                          <option>Select</option>
                          <option value="zakat">ZAKAT</option>
                          <option value="sadaqa">SADAQA</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Amount</label>
                        {curPck && curPck.n === "Other" ? (
                          <input
                            className="form-control"
                            value={curPck ? curPck.a : null}
                            onChange={(e) => {
                              e.preventDefault();
                              CurPck({ ...curPck, a: e.target.value });
                            }}
                          />
                        ) : (
                          <div className="form-control">
                            £ {curPck?.a}
                            {curPck.p === "rec" ? "/month" : ""}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <h5></h5>
                      <div>
                        {curPck?.p === "rec" ? (
                          <label className="btn btn-primary">Recurring</label>
                        ) : (
                          <label
                            className="btn btn-sm cmpBtn text-white"
                            style={{ marginTop: 19 }}
                          >
                            Once
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="12">
                <div className="">
                  <h3 className="donateTitle">Phone Number</h3>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>
                          Country <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          required
                          value={phone.cn}
                          onChange={(e) =>
                            Phone({ ...phone, cn: e.target.value })
                          }
                        >
                          <option value="">Select</option>
                          <option value="44">UK (+44)</option>
                          <option value="880">Bangladesh (+880)</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label>
                          Number <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="79 0000 0000"
                          value={phone.n}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            Phone({ ...phone, n: e.target.value });
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="donateTitle">Name</h3>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Title</label>
                        <select
                          className="form-control"
                          value={name.t}
                          onChange={(e) => Name({ ...name, t: e.target.value })}
                        >
                          <option>Select</option>
                          <option value="mr">Mr</option>
                          <option value="ms">Ms</option>
                          <option value="mrs">Mrs</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>
                          First <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={name.f}
                          onChange={(e) => Name({ ...name, f: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>
                          Last <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={name.l}
                          onChange={(e) => Name({ ...name, l: e.target.value })}
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="donateTitle">Email</h3>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="hello@yourcomapny.com"
                          value={email}
                          onChange={(e) => Email(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <h3 className="donateTitle">Address</h3>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Address Lookup</label>
                        <Autocomplete
                          apiKey={"AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M"}
                          onPlaceSelected={(place) => {
                            console.log(place);
                            let addr = {
                              l1: "",
                              l2: "",
                              c: "",
                              p: "",
                              cn: "",
                              pc: "",
                            };

                            place.address_components.forEach((addr_details) => {
                              const { short_name, types } = addr_details;

                              if (types.includes("postal_code")) {
                                Addr((ps) => {
                                  ps.pc = short_name.trim();
                                  return { ...ps };
                                });
                              } else if (types.includes("route")) {
                                Addr((ps) => {
                                  ps.l2 = short_name.trim();
                                  return { ...ps };
                                });
                              } else if (types.includes("postal_town")) {
                                Addr((ps) => {
                                  ps.c = short_name.trim();
                                  return { ...ps };
                                });
                              } else if (
                                types.includes("administrative_area_level_2")
                              ) {
                                Addr((ps) => {
                                  ps.p = short_name.trim();
                                  return { ...ps };
                                });
                              } else if (types.includes("country")) {
                                Addr((ps) => {
                                  ps.cn = short_name.trim();
                                  return { ...ps };
                                });
                              }
                            });
                          }}
                          className="form-control"
                          placeholder="Search"
                          options={{
                            types: ["(regions)"],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>
                          Address Line 1 <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Address Line 1 (*)"
                          value={addr.l1}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            Addr({ ...addr, l1: e.target.value });
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Address Line 2</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Address Line 2"
                          value={addr.l2}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            Addr({ ...addr, l2: e.target.value });
                          }}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="City (*)"
                          value={addr.c}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            Addr({ ...addr, c: e.target.value });
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Country / Province</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="County / Province"
                          value={addr.p}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            Addr({ ...addr, p: e.target.value });
                          }}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Postcode</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Postcode (*)"
                          value={addr.pc}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            Addr({ ...addr, pc: e.target.value });
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Country</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Country"
                          value={addr.cn}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            Addr({ ...addr, cn: e.target.value });
                          }}
                          // required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="12">
                <div className="row">
                  {/* <div className="col-sm-3">
                    <h3 className="donateTitle">Payment Method</h3>
                    <div className="form-group">
                      <button
                        className="form-control border-info"
                        style={{
                          backgroundColor:
                            "card" === payment.m ? "#026666" : null,
                          color: "card" === payment.m ? "#fff" : null,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          Payment({ m: "card", s: "processing" });
                        }}
                      >
                        Card
                      </button>
                    </div>
                  </div> */}
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <button className="form-control border-info">Cash</button>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <button className="form-control border-info">
                        Cheque
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <button class="form-control border-info">
                        Bank Transfer
                      </button>
                    </div>
                  </div> */}
                </div>
              </Col>
              <Col sm="12">
                <div className="row">
                  <div className="col-sm-6">
                    {
                        stripePromise &&
                        <Elements stripe={stripePromise}>
                          <CardForm
                              amount={parseInt((curPck?.a || 0) * 100, 10)}
                              ref={cardRef}
                              label={curDonType}
                              isRecurring={curPck.p === "rec"}
                              package={curPck}
                              cus={{phone, name, addr, email}}
                              onSuccess={(obj) => {
                                console.log("!!!!!!!!!!!!!!!! : ", obj);
                                SetPayObj(obj);
                              }}
                          />
                        </Elements>
                    }
                  </div>
                </div>
              </Col>
              <Col sm="12" className="consent">
                <h3 class="donateTitle">Consent</h3>

                <div className="row">
                  <div className="col-sm-6 border rounded p-3">
                    <h5>
                      Gift Aid
                      <span className="pull-right" style={{ fontSize: 12 }}>
                        OFF &nbsp;
                        <Switch
                          value={isGiftAid}
                          onChange={() => IsGiftAid(!isGiftAid)}
                        />
                        &nbsp; ON
                      </span>
                    </h5>
                    <p className="donate-description">
                      I am a UK taxpayer and i wish ({"this charity"}) to
                      reclaim tax back on all donations i have made with the
                      last 6 years and all donations that i make hereafter.
                    </p>
                  </div>
                  <div className="col-sm-6 border rounded p-3">
                    <h5>
                      Consent (Project)
                      <span className="pull-right" style={{ fontSize: 12 }}>
                        OFF &nbsp;
                        <Switch
                          // checked={isConsentProj}
                          // onChange={() => IsConsentProj(!isConsentProj)}
                          value={isConsent}
                          onChange={() => IsConsent(!isConsent)}
                        />
                        &nbsp; ON
                      </span>
                    </h5>
                    <p className="donate-description">
                      I consent to receive news and updates from this charity
                      and about this campaign.
                    </p>
                  </div>
                </div>
              </Col>

              <Col sm="12" className="mt-4 p-0">
                <div className="">
                  <h3 className="donateTitle">Message</h3>
                  <textarea
                    className="form-control p-3"
                    type="text"
                    maxLength="280"
                    rows="100"
                    cols="50"
                    placeholder="Max 280 Charecter"
                    value={msg}
                    onChange={(e) => {
                      SetMsg(e.target.value);
                    }}
                  ></textarea>
                  <span id="chars">{280 - msg.length}</span> characters
                  remaining
                </div>
              </Col>
              {/* <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <h3 class="donateTitle">Message</h3>
                    <input
                      className="form-control"
                      type="text"
                      maxLength="280"
                      placeholder="Max 280 Charecter"
                      value={msg}
                      onChange={(e) => {
                        SetMsg(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-sm-12">
                  {/* <div className="form-group ml-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="anonymous"
                      name="anonymous"
                      checked={isAnonymous}
                      onChange={(_) => SetAnonymous((ps) => !ps)}
                    />
                    Donate anonymously
                  </div> */}
                  <label className="form-check-label ml-3 mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="anonymous"
                      name="anonymous"
                      checked={isAnonymous}
                      onChange={(_) => SetAnonymous((ps) => !ps)}
                    />
                    <span className="form-check-sign"></span>Donate anonymously
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn-block btn btn-green mt-5"
                      disabled={isProcessing}
                    >
                      {isProcessing ? "Processing.." : "Process Donation"}
                    </button>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group"></div>
                </div>
              </div>
            </Form>
            <AuiModal
              bOpen={showPackageList}
              OnClose={() => SetShowPackageList(false)}
            >
              <ListPack
                pk={curCamp ? curCamp.pk : []}
                OnSelect={(pack) => {
                  console.log("Donations_Internal_New: ListPack: pack: ", pack);
                  CurPck(pack);
                }}
                OnClose={() => SetShowPackageList(false)}
              />
            </AuiModal>
            {/* <Button
              color="primary"
              type="button"
              onClick={() => setModalLive(true)}
            >
              Launch demo modal
            </Button>{" "} */}
            <Modal
              toggle={() => setModalLive(false)}
              isOpen={modalLive}
              className="modal-md"
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              {/* <div className="modal-body">
                <p className="text-center">
                  {" "}
                  Do you want to sign in first before continuing?
                </p>
              </div> */}
              <div className="modal-body">
                <Button
                  className="btn cmpBtn btn-block"
                  onClick={() => {
                    navigate("/auth/login", { state: { fromUri: props.uri } });
                    setModalLive(false);
                  }}
                >
                  Continue as a Guest
                </Button>
                {/* <Button
                  className="btn btn-primary btn-block"
                  style={{ fontSize: 18 }}
                  onClick={() => {
                    SetAuthCheck(false);
                    setModalLive(false);
                  }}
                >
                  Sign In
                </Button> */}
              </div>
            </Modal>
          </Container>
        </div>
      </div>

      <>
        {isBusy && (
          <div>
            <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
            >
              <Loader
                type="box-rectangular"
                bgColor={"#007bff"}
                title={"Processing ..."}
                color={"#007bff"}
                size={150}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
}

// End Modal
// export default Donation;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CrowdDonation);
