import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Sidebar from "./news-sidebar.js";
function SingleNews(props) {
  const news = props.location.state;
  console.log(news);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + news?.url ||
                require("assets/img/works/free-medical/medical-2.JPG").default +
                  ")",
            }}
          />

          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white ">
                {[news?.pi?.t, news?.ci?.t].join("  ")}
              </h3>
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" md="9" className="mx-auto">
                {news?.ld.map((val) => {
                  if (val.t === "txt") {
                    return <p className="mt-5">{val.d}</p>;
                  } else {
                    return (
                      <img
                        alt="..."
                        className="rounded img-fluid img-raised"
                        src={val.d.url}
                      />
                    );
                  }
                })}
              </Col>
              {/* <Col sm="3">
                <h4 className="title" style={{ fontSize: 18 }}>
                  Recent News Design 1
                </h4>

                <Sidebar />
              </Col> */}
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleNews;
