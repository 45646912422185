import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col, Card } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function About() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h2 href="#" className="text-white title p-0">
                About Us
              </h2>
            </Container>
          </div>
        </div>
        <div className="section section-campaign">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="4">
                <img
                  style={{ maxWidth: "80%" }}
                  alt="..."
                  className="rounded img-fluid"
                  src={require("assets/img/lulu-briggs.png").default}
                ></img>
                <h4 className="category mt-3" style={{ fontSize: 17 }}>
                  High Chief Dr O.B. Lulu-Briggs OON DCF DSSRS
                </h4>
              </Col>
              <Col className="ml-auto mr-auto aboutContent" md="8">
                <h5>
                  In 2001, Dr. (Mrs.) Seinye O.B. Lulu-Briggs established the
                  O.B. Lulu-Briggs Foundation in the name of her beloved
                  husband, High Chief (Dr.) O.B. Lulu-Briggs, OON, DCF, DSSRS,
                  (1930-2018), to honor, celebrate and institutionalize his
                  legendary giving. His love and commitment to humanity shone
                  through his charitable and philanthropic acts which equipped,
                  empowered, and enabled people – particularly the most
                  vulnerable and under-served – to live full, purposeful, and
                  dignified lives.
                  <br />
                  <br /> He donated buildings and supported institutions that
                  educate, care for, and secure people’s spiritual and material
                  well-being in the Niger Delta, across Nigeria and around the
                  world. This inspired our mission, which is to{" "}
                  <b>
                    implement sustainable interventions that enhance the quality
                    of life for under-served persons.
                  </b>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <h3
                  className="text-info font-italic"
                  style={{ lineHeight: "40px" }}
                ></h3>

                {/* <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/T4HFoMOT6iM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div> */}

                <h3 className="title">Our Vision</h3>
                <p>A world where people are free from despair.</p>
                <h3 className="title">Our Mission</h3>
                <p>
                  We work to enhance the quality of life of people and
                  communities by creating structured interventions which combat
                  inequality and injustice. We want to create lasting change
                  that empowers people and builds positive mindsets.
                </p>
              </Col>
            </Row>
          </Container>
          <Container>
            <h2 className="title">Board Of Trustees</h2>
            <div className="team text-center">
              <Row>
                <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid"
                      src={require("assets/img/f-avatar.png").default}
                    ></img>
                    <h4 className="mt-5">Dr (Mrs) Seinye O. B. Lulu-Briggs</h4>
                    <p className="category text-info">Chair</p>
                  </Card>
                </Col>

                <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid"
                      src={require("assets/img/default-avatar.png").default}
                    ></img>
                    <h4 className="mt-5">Ganiyu Leslie Laniyan MBE</h4>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default About;
