/**
 *	@copyright	KARD..
 */

// import * as Cfg from '../../_cfg'
// import CfgCore from '../../_cfg/core'
// import {Lambda as CfgLambda} from '../../_cfg/back'

import * as AWS from '../../api/AWS'

// import {Lambda, Core} from '../../api/AWS'

// import { Time } from 'api/Utils'

import * as Cfg from './cfg'

/**
 */
export const Crowd_DonationList = ({id}) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const lambda_name = d( Cfg.Lambda('unauth') )

            // console.log( 'actions/crowd: Crowd_DonationList: lambda_name: ', lambda_name )

            const p_list = {
                actType: 'crowd',
                act: 'crowd:dontlist',
                c_id: gs().__cfg.oId,

                data: {id}
            }   // p_list

            // console.log( 'actions/crowd: Crowd_DonationList: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/crowd: Crowd_DonationList: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        k: {
                            ...x.k, a: x.k.a / 100.0,
                        },
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            // console.log( 'actions/crowd: Crowd_DonationList: mapped_data: ', mapped_data )

            //
            return {...resp_list, Items: mapped_data}

            // return {...resp_list, Items: mapped_data}
            // return resp_list
        }
        catch( err )
        {
            console.warn( 'rdx.Crowd_DonationList: err: ', err )

            return Promise.reject( err )
        }
    }   // ...
}   // Crowd_DonationList

/**
 */
export const Crowd_List = ( {
    b_more,
    // last_key,
    n_elem = 10,
} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            // console.log( 'actions/crowd: Crowd_List: gs(): ', gs() )
            // console.log( 'actions/crowd: Crowd_List: gs().__auth.status: ', gs().__auth.status )
            // console.log( 'actions/crowd: Crowd_List: CfgCore: ', CfgCore )
            // console.log( 'actions/crowd: Crowd_List: CfgLambda: ', CfgLambda )

            d( _Crowd_IsLoading(true) )

            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            const lambda_name = 'loggedin' === gs().__auth.status ? d( Cfg.Lambda('app') ) : d( Cfg.Lambda('unauth') )

            // console.log( 'actions/crowd: Crowd_List: lambda_name: ', lambda_name )

            const p_list = {
                actType: 'crowd',
                act: 'crowd:list',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    // last_key,
                    last_key: b_more ? gs().__crowd.last : null,
                    n_elem,
                },
            }

            // console.log( 'actions/crowd: Crowd_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/crowd: Crowd_List: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        a: {
                            ...x.a, t: x.a.t / 100.0, p: x.a.p / 100.0, r: x.a.r / 100.0,
                        },
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            const old_list = gs().__crowd.list

            d( _Crowd_List( [...old_list, ...mapped_data], resp_list.LastEvaluatedKey ) )

            d( _Crowd_IsLoading(false) )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'rdx.Crowd_List: err: ', err )

            d( _Crowd_IsLoading(false) )

            return Promise.reject( err )
        }
    }   // ...
}   // Crowd_List

/**
 */
export const Crowd_Profile = ( {username} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_get = {
                actType: 'prof',
                act: 'prof:get',
                c_id: gs().__cfg.oId,

                data: {
                    username
                }
            }

            const resp_get = await aws_lambda.Invoke( p_get, lambda_name )

            //
            return resp_get
        }
        catch( err )
        {
            console.warn( 'rdx.Crowd_Profile: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Crowd_Profile

/**
 */
const _Crowd_List = ( list, last ) =>
{
    return {
        type: 'crowd:list',
        payload: {list, last},
    }
}   // _Crowd_List

/**
 */
const _Crowd_IsLoading = ( is_loading ) =>
{
    return {
        type: 'crowd:is_loading',
        payload: is_loading,
    }
}   // _Crowd_IsLoading

