import React from "react";
import "../home/style.css";
// reactstrap components

// core components

function Cookie() {
  const [cookieAccept, setCookieAccept] = React.useState(true);
  //   const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    //
    const cookie_accept = localStorage.getItem("cookie_accept");
    console.log(cookie_accept);
    setCookieAccept(cookie_accept);
  }, []);
  return (
    <>
      {!cookieAccept && (
        <div className="fixed-bottom alert bg-dark cookiealert" role="alert">
          <div className="cookieContainer">
            <div className="cookieImage col-lg-2">
              <img
                width="150px"
                alt="img"
                className="rounded img-fluid  my-4"
                src={require("assets/img/shield.png").default}
              ></img>
            </div>
            <div className="cookieText col-lg-8">
              <h2 className="font-weight-bold">We value your privacy</h2>
              We are using cookies to help our website function properly, for
              analytics, marketing and to personalise the content you see. By
              accepting, you agree to the ise of all of these cookies. You can
              update preferences by clicking the cookie preference button or at
              any time by going to our coolie policy.
            </div>
            <div className="cookieBtn col-lg-2">
              <button
                type="button"
                className="btn btn-info btn-block acceptcookies"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  localStorage.setItem("cookie_accept", true);
                  setCookieAccept(true);
                }}
              >
                Accept
              </button>
              {/* <button
                type="button"
                className="btn btn-outline-secondary btn-block "
              >
                Cookie preferences
              </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Cookie;
