/**
 * KARD
 */

/**
 */
export const Title = ( {t} ) =>
{
    switch( t )
    {
        case 'mr': return 'Mr'
        case 'ms': return 'Mr'
        case 'mr': return 'Ms'
        case 'mrs': return 'Mrs'
        case 'dr': return 'Dr'

        defualt: return null
    }
}   // Title

/**
 */
export const Name = ( {t, f, m, l} ) =>
{
    const t_ready = Title( {t} )

    const arr_prep = [
        t_ready ? t_ready : null,
        f && f.length ? f : null,
        m && m.length ? m : null,
        l && l.length ? l : null,
    ]

    const arr_filt = arr_prep.filter( x => x )

    return arr_filt.join( ' ' )
}   // Name

/**
 */
export const AddrFull = ( {l1, l2, c, p, cn, pc} ) =>
{
    const arr_prep = [
        l1 && l1.length ? l1 : null,
        l2 && l2.length ? l2 : null,
        c && c.length ? c : null,
        p && p.length ? p : null,
        cn && cn.length ? cn : null,
        pc && pc.length ? pc : null,
    ]

    const arr_filt = arr_prep.filter( x => x )

    return arr_filt.join( ', ' )
}

/**
 */
export const Phone = ( {cn, c} ) =>
{
    if( !cn || !c )
    {
        return ''
    }

    return `+${cn ? cn : '--'} ${c}`
}   // Name

