import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
  Container,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import {useDispatch} from "react-redux";
import {AuthForgotPassword} from "../../rdx/actions";

function ForgotPassword() {
  const dispatch = useDispatch()
  const [email,SetEmail] = useState(null)
  // const []
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function requestCode (e)
  {
    e.preventDefault()
    dispatch(AuthForgotPassword(email))
        .then(data=>console.log(data))
        .catch()
  }

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section registration" style={{ marginTop: "2rem" }}>
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <h3 className="text-center mb-5">Forgot Password</h3>
              <Form onSubmit={requestCode}>
                <FormGroup>
                  <label htmlFor="exampleInputEmail1">Enter Your Email</label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{ padding: "10px" }}>
                        <i className="fa fa-envelope"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="user@fundraiser.com"
                      type="email"
                      onChange={e=>SetEmail(e.target.value.trim())}
                    />
                  </InputGroup>
                </FormGroup>

                <Button color="info" type="submit" style={{ width: "100%" }}>
                  RESET PASSWORD
                </Button>
                <p className="text-center">
                  Back to{""}
                  <a
                    href=""
                    className="font-weight-bold"
                    style={{ color: "#2ca8ff" }}
                  >
                    Login
                  </a>
                </p>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
