import React from "react";
import hero from "assets/img/high-5.png";
// reactstrap components
import {
  Button,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
  Container,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

import IndexNavbar from "components/Navbars/IndexNavbar.js";

/**
 */
function StartCampaign(props) {
  return (
    <>
      <IndexNavbar />
      <div className="section start-fundraiser">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            {"loggedin" === props.__auth.status ? (
              <Col className="mx-auto center-block" sm="6">
                <img src={hero} alt="Image" width="80%" />

                <Form>
                  {/* <h2 className="mt-5 font-weight-bold">
                    Who are you raising money for ?
                  </h2>

                  <FormGroup check className="form-check-radio" inline>
                    <Label check>
                      <Input
                        defaultValue="option1"
                        id="inlineRadio1"
                        name="inlineRadioOptions"
                        type="radio"
                      ></Input>
                      Business or Individual{" "}
                      <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio mb-5" inline>
                    <Label check>
                      <Input
                        defaultValue="option2"
                        id="inlineRadio2"
                        name="inlineRadioOptions"
                        type="radio"
                      ></Input>
                      Personal <span className="form-check-sign"></span>
                    </Label>
                  </FormGroup> */}

                  <button
                    className="btn btn-info btn-lg mt-5"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate("/crowd/campaign/create/basics");
                    }}
                  >
                    Start A Campaign
                  </button>
                </Form>
              </Col>
            ) : (
              <Col lg="8" md="12">
                <h2 className="title">Please Log In first</h2>
                <button
                  className="btn btn-info btn-lg "
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/auth/login");
                  }}
                >
                  LogIn
                </button>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
} // StartCampaign

/**
 */
// export default StartCampaign;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(StartCampaign);
