/**
 *	@copyright	KARD.
 */

import React from "react";

import { navigate, Link } from "@reach/router";

// reactstrap components
import {
  Button,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
  Container,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import ReactCodeInput from "react-verification-code-input";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Password from "./password";

/**
 */
const RegistrationPage = (props) => {
  const [isBusy, IsBusy] = React.useState(false);
  const [showing, Showing] = React.useState("form");

  const [name, Name] = React.useState({ t: "", f: "", l: "" });
  const [email, Email] = React.useState("");
  // const [phone, Phone] = React.useState( {cn: '', n: ''} )
  const [password, SetPassword] = React.useState(null);

  /**
   */
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  /**
   */
  const OnRegister = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if( !password.length )
    // {
    //   alert( 'Empty password not allowed' )

    //   return false
    // }

    if (!password?.match || !password?.valid)
    {
      console.log(password)
      return false;
    }


    Register().catch((err) => {});
  }; // OnRegister

  /**
   */
  const Register = async () => {
    try {
      IsBusy(true);

      await props.Auth_Register({
        email,
        // phone,
        pass: password.p,
      });

      Showing("veri");

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("auth:Register: Register: err: ", err);

      IsBusy(false);

      return { err };
    }
  }; // Register

  /**
   */
  const OnConfirm = async (code) => {
    try {
      // console.log( 'auth:Register: OnConfirm: code: ', code )

      IsBusy(true);

      const cr = await props.Auth_Comfirm({ email, code });
      console.log(cr)

      const usr_obj = await props.Auth_Login({ email, pass: password.p });

      // console.log( 'auth:Register: OnConfirm: usr_obj: ', usr_obj )

      await props.Profile_Init({ email, name });

      if (usr_obj.challengeName === "NEW_PASSWORD_REQUIRED") {
        // console.log( 'navigating to /auth/complete' )

        navigate("/auth/complete");
      } else {
        navigate("/");
      }

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("auth:Register: OnConfirm: err: ", err);

      IsBusy(false);

      return { err };
    }
  }; // Register

  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section registration" style={{ marginTop: "2rem" }}>
          <Container>
            {"form" === showing ? (
              <Col className="ml-auto mr-auto" md="4">
                <h3 className="text-center">Sign Up</h3>
                <Form onSubmit={OnRegister}>
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">First Name</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ padding: "10px" }}>
                          <i className="fa fa-user-circle"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="John"
                        type="text"
                        value={name.f}
                        onChange={(e) => Name({ ...name, f: e.target.value })}
                        required
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">Last Name</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ padding: "10px" }}>
                          <i className="fa fa-user-circle"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Doe"
                        type="text"
                        value={name.fl}
                        onChange={(e) => Name({ ...name, l: e.target.value })}
                        required
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ padding: "10px" }}>
                          <i className="fa fa-envelope"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="user@fundraiser.com"
                        type="email"
                        value={email}
                        onChange={(e) => Email(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <FormText
                      className="text-muted"
                      style={{ fontSize: 12 }}
                      color="default"
                      id="emailHelp"
                    >
                      We'll never share your email with anyone else.
                    </FormText>
                  </FormGroup>

                  <Password label="Password" requirement={true} confirmPass={true} getPassword={SetPassword}/>



                  {/* <FormGroup check className="mt-5">
                  <Label check>
                    <Input type="checkbox"></Input>
                    <span className="form-check-sign"></span>I Have Read Terms &
                    Conditions
                  </Label>
                </FormGroup> */}

                  <Button
                    color="info"
                    type="submit"
                    style={{ width: "100%" }}
                    // onClick={OnRegister}
                  >
                    SIGN UP
                  </Button>
                  <p className="text-center">
                    Already Have An Account?{" "}
                    <Link to="/auth/login">
                      <a
                        href=""
                        className="font-weight-bold"
                        style={{ color: "#2ca8ff" }}
                      >
                        Sign In
                      </a>
                    </Link>
                  </p>
                </Form>
              </Col>
            ) : (
              <Col className="ml-auto mr-auto" md="4">
                <h3>Confirmation Code</h3>
                <ReactCodeInput onComplete={(code) => OnConfirm(code)} />
              </Col>
            )}
          </Container>
        </div>
      </div>
    </>
  );
};

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(RegistrationPage);
