import React from "react";
import "../home/style.css";
// reactstrap components
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Team() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h2 href="#" className="text-white title p-0">
                Our Team
              </h2>
            </Container>
          </div>
        </div>

        <div className="section section-team text-center">
          <Container>
            <p>
              We have a passionate and committed staff of forty, twenty-two
              based in our Port Harcourt office and eighteen in the field. Our
              programming is complemented by a pool of medical and non-medical
              volunteers.
            </p>
            <h2 className="title">Board Of Trustees</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid"
                      src={require("assets/img/f-avatar.png").default}
                    ></img>
                    <h4 className="mt-5">Dr (Mrs) Seinye O. B. Lulu-Briggs</h4>
                    <p className="category text-info">Chair</p>
                  </Card>
                </Col>

                <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid"
                      src={require("assets/img/default-avatar.png").default}
                    ></img>
                    <h4 className="mt-5">Ganiyu Leslie Laniyan MBE</h4>
                  </Card>
                </Col>
                {/* <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/default-avatar.png").default}
                    ></img>
                    <h3 className="mt-5">Mr Tope Solola</h3>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/f-avatar.png").default}
                    ></img>
                    <h3 className="mt-5">Mrs Aima Obriango</h3>
                  </Card>
                </Col> */}
              </Row>
            </div>
            {/* Operation */}
            {/* <h2 className="title">Operations Team</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/f-avatar.png").default}
                    ></img>
                    <h3 className="mt-5">Mrs Ineba Tongkam</h3>
                    <p className="category text-info">
                      Coordinator of Programmes
                    </p>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="team-player py-4">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/default-avatar.png").default}
                    ></img>
                    <h3 className="mt-5">Mr Tekena Sukubo</h3>
                    <p className="category text-info">
                      Head, Finance and Administration
                    </p>
                  </Card>
                </Col>
              </Row>
            </div> */}
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default Team;
