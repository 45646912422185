import React from "react";
import "./style.css";
// reactstrap components
import { Container, Row } from "reactstrap";

function LiveLittlehope() {
  return (
    <>
      <div className="section section-basic mt-5" id="basic-elements">
        <Container>
          <h2 className="title">Live Appeal </h2>
          <Row>
            <div class="embed-responsive embed-responsive-21by9">
              <iframe
                width="1904"
                height="768"
                src="https://www.youtube.com/embed/I5UBikauIQM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LiveLittlehope;
