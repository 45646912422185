import React, {useRef} from 'react'
import {Button} from "reactstrap";

function ImageInput(props) {

    const iIRef = useRef()
    async function handleFileRead(event) {
        const { name, value } = event.target;
        const file = event.target.files[0];
        const url = URL.createObjectURL(file);

        const doc = {
            file: file,
            type: file.type,
            name: file.name,
            path: url,
        };
        if (!file) return { msg: "Image not found" };

        props.onChange(doc)
    }

    return(
        <div>
            <input
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                id="contained-button-file"
                ref={iIRef}
                onChange={handleFileRead}
            />
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    if (iIRef.current)
                    {
                        iIRef.current.click();
                    }
                }}
                variant="contained"
                color="primary"
                component="span"
                className={props?.className||"btn btn-green"}
            >
                {props?.label||'Change Image'}
            </Button>

        </div>
    )
}

export {ImageInput}
