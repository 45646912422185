import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Sidebar from "./work-sidebar.js";

function CareForLife() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/care/care-1.jpg").default +
                ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white">
                Care For Life
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext/1642076658/1642077375923");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p>
                  More than 9 million Nigerians are aged 60 and above and
                  according to the global age index, this number will be over 20
                  million by 2030. However, currently there are very few
                  organisations and institutions which focus on supporting the
                  elderly in Nigeria.
                </p>

                <p>
                  We at O.B. Lulu-Briggs Foundation place the elderly at the
                  centre of our work. It is not only altruistic, but a duty upon
                  us all as members of a global community, to support and care
                  for those without the means, capacity, and ability to do so.
                  The elderly are among the most vulnerable in our society,
                  often neglected and unsupported – many without families to
                  rely on. Culturally and religiously, it is a widespread
                  practice in the Global South, to look after our elders – this
                  is more important now than ever before due to the spread of
                  COVID-19, which disproportionately affects the elderly in our
                  community, and has wiped out millions of elderly people around
                  the world.
                </p>
                <img
                  style={{ width: "100%" }}
                  alt="..."
                  className="rounded img-fluid center-img my-3"
                  src={require("assets/img/works/care/care-3.jpg").default}
                ></img>
                <p>
                  Our first programme, which remains our flagship initiative,
                  was Care for Life, also known as Care for the Elderly
                  Programme. This programme identifies and caters to the sick
                  and neglected senior citizens across Rivers State, Nigeria. We
                  take care of their health, economic and social well-being by
                  providing comprehensive healthcare; caregivers to look after
                  them, monthly food supplies, and monthly cash allowances.
                </p>
                <p>
                  In Akuku-Toru Local Government Area, Rivers State, we provide
                  the elderly two nutritious meals every weekday, along with
                  healthcare, as well as organizing social events such as
                  anniversary celebrations, annual New Year and Valentine’s Day
                  parties for them at a modern purpose-built recreational center
                  at Biokpo, Abonnema. A second facility is being developed in
                  Abua and will be opened soon. We try to meet the needs of the
                  elderly through a holistic approach – by providing them
                  opportunities to socialize, meet new people, celebrate
                  milestones, as well as meeting their basic needs of food,
                  nutrition and healthcare. It is only through addressing their
                  physical, mental and emotional needs, that we can enable the
                  elderly to live fruitful, meaningful lives with purpose,
                  dignity and safety.
                </p>
                <img
                  style={{ width: "100%" }}
                  alt="..."
                  className="rounded img-fluid center-img my-4"
                  src={require("assets/img/works/care/care-1.jpg").default}
                ></img>
                <h3 className="font-weight-bold">
                  HIGH CHIEF (DR.) O. B. LULU-BRIGGS DISTINGUISHED PROFESSORIAL
                  CHAIR
                </h3>
                <p>
                  On May 22, 2019, we announced a gift of 50 million Naira to
                  endow the High Chief (Dr.) O.B. Lulu-Briggs Distinguished
                  Professorial Chair in Geriatric Care at the Rivers State
                  University.
                </p>
                <p>
                  This auspicious occasion aptly titled A Legacy of
                  Philanthropic Excellence honoured our late grand benefactor’s
                  legendary giving on his first posthumous birthday. We are
                  confident that the outcomes from work under the endowment will
                  contribute to pioneering work needed to secure adequate
                  resources and advocacy that will support and promote healthy
                  aging in Nigeria. Supporting research in this field is
                  incredibly important both to promote education and health.
                </p>
                {/* <a
                  className="btn btn-lg text-white pt-3 cmpBtn"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/donation/ext/1642076658/1642077375923");
                  }}
                >
                  Donate
                </a> */}
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default CareForLife;
