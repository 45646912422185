import React from "react";
import "../home/style.css";
import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Col,
  InputGroup,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { navigate, Link } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import CreateSidebar from "./create_sidebar";

function CreateCampaignFunding(props) {
  const [target, Target] = React.useState(props.__ccamp.funding.target);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="container-fluid">
          <div className="flex-xl-nowrap row">
            {/* Sidebar */}
            <CreateSidebar section="funding" />

            {/* Body */}
            <main class="bd-content col-12 col-md-9 col-xl-8 pl-md-5 py-md-3">
              <div className="row">
                <div className="col-sm-8">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Campaign</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Funding</BreadcrumbItem>
                  </Breadcrumb>
                </div>
                {/* <div className="col-sm-4">
                  <button className="btn btn-outline">Save Campaign</button>
                  <button className="btn btn-info">Review & Lunch</button>
                </div> */}
              </div>
              <h1 class="bd-title" id="content">
                Funding
              </h1>
              <Col sm="12" className=" p-0 create-campaign">
                <Form
                  onSubmit={ e => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.CrowdCampaign_Funding({ target });

                    navigate("/crowd/campaign/create/content");
                  } }
                >
                  <FormGroup className="mb-5">
                    <label className="campTitle">Target Amount *</label>
                    <Input
                      aria-describedby="emailHelp"
                      id="campTitle"
                      placeholder="5000"
                      type="number"
                      value={target}
                      onChange={(e) => Target(e.target.value)}
                      required
                    ></Input>
                  </FormGroup>

                  <div className="btn-group">
                    <Button
                      className="mt-2 btn-lg text-center mr-2"
                      color="primary"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navigate("/crowd/campaign/create/category");
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      className="mt-2 btn-lg btn-green text-center"
                      type="submit"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   e.stopPropagation();
                      //   props.CrowdCampaign_Funding({ target });

                      //   navigate("/crowd/campaign/create/content");
                      // }}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </Form>
              </Col>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

// export default CreateCampaignFunding;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CreateCampaignFunding);
