/**
 *	@copyright	KARD.
 */

/**
 */
export default ( state = {
    // cn: {},

    // isLoggedIn: false,
    status: 'init',
    // roles: [],
    // sessStart: 0,
    // usr: null,
    // orgId: '',
}, action ) =>
{
    switch( action.type )
    {
    // case 'auth:init':
    //     return {...state, cn: action.payload.cn, cognito: action.payload.cognito}

    // case 'auth:roi':
    //     return {...state, roi: action.payload}

    // case 'auth:cn':
    //     return {...state, cn: action.payload}

    case 'auth:status':
        return {...state, status: action.payload}

    // case 'auth:roles':
    //     return {...state, roles: action.payload}

    case 'auth:usr':
        return {...state, usr: action.payload}

    // case 'auth:cred':
    //     return {...state, cred: {username: action.payload.username, pass: action.payload.pass}}

    // case 'auth:sessStart':
    //     return {...state, sessStart: action.payload}

    // case 'auth:org_id':
    //     return {...state, orgId: action.payload}

    default:
        return state
    }   // switch ...
}   // export default



