/**
 *	@copyright	KARD.
 */

// import * as Cfg from '../../_cfg'

/**
 */
export default ( state = {
    basic: {
        title: '',
        tagline: '',
        dateStart: '',
        dateEnd: '',
    },
    project: {
        proj: null,
        camp: null,
    },
    funding: {
        target: '',
    },
    content: {
        profImg: {},
        profVid: '',

        listDesc: [],

        socFb: '',
        socInsta: '',
        socTwit: '',
        socYT: '',
    },
}, action ) =>
{
    switch( action.type )
    {
    case 'crowd-campaign:basic':
        return {...state, basic: action.payload}
    case 'crowd-campaign:project':
        return {...state, project: action.payload}
    case 'crowd-campaign:funding':
        return {...state, funding: action.payload}
    case 'crowd-campaign:content':
        return {...state, content: action.payload}

    default:
        return state
    }   // switch ...
}   // export default



