import React, { useRef, useState } from "react";
import "../home/style.css";
import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Col,
  InputGroup,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { navigate, Link } from "@reach/router";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "rdx/actions";

// core components
import Image from "aui/image";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import CreateSidebar from "./create_sidebar";

// const Time_Epoch = ( ms ) =>
// {
//   return parseInt( ms / 1000.0, 10 )
// }

/**
 */
function CreateCampaignContent(props)
{
  const imgRef = useRef();

  // const [doc, SetDoc] = useState( {
  //   file: null,
  //   name: null,
  //   type: null,
  //   path: null,
      // url: '',
  // } );

  const [doc, SetDoc] = useState( props.__ccamp.content.profImg ? props.__ccamp.content.profImg : {} )
  const [profVid, ProfVid] = useState( props.__ccamp.content.profVid )
  const [socFb, SocFb] = useState( props.__ccamp.content.socFb )
  const [socInsta, SocInsta] = useState( props.__ccamp.content.socInsta )
  const [socTwit, SocTwit] = useState( props.__ccamp.content.socTwit )
  const [socYT, SocYT] = useState( props.__ccamp.content.socYT )

  const [listDesc, ListDesc] = useState( props.__ccamp.content.listDesc && props.__ccamp.content.listDesc.length ?
    props.__ccamp.content.listDesc : [
    { t: 'txt', d: '', tn: Date.now(), },
    // {t: ''},
  ] );

  React.useEffect(() => {
    console.log( 'CreateCampaignContent: props: ', props )
    console.log( 'CreateCampaignContent: doc: ', doc )
  }, []);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  /**
   */
  function handleFileRead(event)
  {
    // const { name, value } = event.target;
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    const doc = {
      file: file,
      type: file.type,
      name: file.name,
      path: url,
    };

    if (!file)
    {
      return { msg: "Image not found" };
    }

    SetDoc({ ...doc });
  } // handleFileRead

  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="container-fluid">
          <div className="flex-xl-nowrap row">
            {/* Sidebar */}
            <CreateSidebar section="content" />

            {/* Body */}
            <main class="bd-content col-12 col-md-9 col-xl-8 pl-md-5 py-md-3">
              <div className="row">
                <div className="col-sm-8">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Campaign</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Content</BreadcrumbItem>
                  </Breadcrumb>
                </div>
                {/* <div className="col-sm-4">
                  <button className="btn btn-outline">Save Campaign</button>
                  <button className="btn btn-info">Review & Lunch</button>
                </div> */}
              </div>
              <h1 class="bd-title" id="content">
                Content
              </h1>

              {/* Uncomment once video url is working */}
              {/*
              <p className="text-muted">
                Add a video or image to appear on the top of your campaign page.
                Campaigns with videos raise 2000% more than campaigns without
                videos. Keep your video 2-3 minutes. Learn how to make a great
                video.
              </p>
              */}
              <Col sm="12" className=" p-0 create-campaign">
                <Form
                  onSubmit={ e => {
                    e.preventDefault()
                    e.stopPropagation()

                    props.CrowdCampaign_Content( {
                      // img: doc,
                      profImg: doc,
                      profVid: profVid,
                      listDesc: listDesc,
                      socFb: socFb,
                      socInsta: socInsta,
                      socTwit: socTwit,
                      socYT: socYT,
                    } )

                    navigate("/crowd/campaign/create/summary");
                  } }
                >
                  <FormGroup className="mb-3">
                    <div>
                      <label className="campTitle">
                        Image for your campaign
                      </label>
                    </div>
                    <Image
                      src={doc.path}
                      alt={require("assets/img/placeholder.png").default}
                      altTxt="image"
                      cn="border"
                      style={{
                        width: 400,
                        maxHeight: 300,
                        minHeight: 300,
                        objectFit: "cover",
                      }}
                    />
                    <InputGroup>
                      <div className="btn-group">
                        {/* <label
                          className="btn"
                          for="btn-check"
                          style={{ background: "#002147" }}
                        >
                          Video
                        </label> */}
                        <Button
                          className="btn btn-primary"
                          for="btn-check"
                          style={{ background: "#A3ACB8" }}
                          onClick={(e) => {
                            e.preventDefault();
                            imgRef.current.click();
                          }}
                        >
                          {/* Image */}
                          Select
                        </Button>
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        style={{ display: "none" }}
                        id="contained-button-file"
                        ref={imgRef}
                        onChange={handleFileRead}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <label className="campTitle">Video URL</label>
                    <p className="text-muted">
                      Enter a YouTube or Vimeo URL to appear at the top of your
                      campaign page. Make sure your video has closed captioning
                      enabled on Youtube or Vimeo.
                    </p>
                    <div className="form-inline">
                      <i className="fab fa-youtube btn btn-green" />
                      <Input
                        className="col-sm-10"
                        id="social"
                        placeholder="Video URL"
                        type="text"
                        value={profVid}
                        onChange={ e => {
                          ProfVid( e.target.value )
                        } }
                      ></Input>
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <label className="campTitle">Describe your campaign</label>
                    {/* <textarea
                      className="form-control col-sm-10 mt-3"
                      rows="30"
                      col="50"
                      id="social"
                      placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
                      type="text"
                    ></textarea> */}
                    {listDesc.map((x, i, a) => (
                      <DescComp
                        key={x.tn}
                        // id={['social', i].join('-')}
                        t={x.t}
                        d={x.d}
                        // value={x.t}
                        OnChange={(t, d) => {
                          // a[i].t = val;
                          a[i].t = t
                          a[i].d = d

                          ListDesc([...a]);
                        }}
                        OnRem={() => {
                          // console.log( 'OnRem: i: ', i )
                          a.splice(i, 1);
                          // console.log( 'OnRem: a: ', a )
                          ListDesc([...a]);
                        }}
                      />
                    ))}
                  </FormGroup>
                  <Button
                    className="btn btn-primary"
                    for="btn-check"
                    style={{ background: "#A3ACB8" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      ListDesc([
                        ...listDesc,
                        { t: 'txt', d: '', tn: Date.now(), },
                      ]);
                    }}
                  >
                    Add Section
                  </Button>
                  {/* 
                  <FormGroup className="form-inline">
                    <i className="fab fa-youtube btn btn-green" />
                    <Input
                      className="col-sm-10"
                      id="social"
                      placeholder="Video URL"
                      type="text"
                    ></Input>
                  </FormGroup> */}

                  <FormGroup className="form-inline">
                    <i className="fab fa-facebook btn btn-green" />
                    <Input
                      className="col-sm-10"
                      id="social"
                      placeholder="http://"
                      type="text"
                      value={socFb}
                      onChange={ e => {
                        SocFb( e.target.value )
                      } }
                    ></Input>
                  </FormGroup>

                  <FormGroup className="form-inline">
                    <i className="fab fa-instagram btn btn-green" />
                    <Input
                      className="col-sm-10"
                      id="social"
                      placeholder="http://"
                      type="text"
                      value={socInsta}
                      onChange={ e => {
                        SocInsta( e.target.value )
                      } }
                    ></Input>
                  </FormGroup>

                  <FormGroup className="form-inline">
                    <i className="fab fa-twitter btn btn-green" />
                    <Input
                      className="col-sm-10"
                      id="social"
                      placeholder="http://"
                      type="text"
                      value={socTwit}
                      onChange={ e => {
                        SocTwit( e.target.value )
                      } }
                    ></Input>
                  </FormGroup>

                  <FormGroup className="form-inline">
                    <i className="fab fa-youtube btn btn-green" />
                    <Input
                      className="col-sm-10"
                      id="social"
                      placeholder="http://"
                      type="text"
                      value={socYT}
                      onChange={ e => {
                        SocYT( e.target.value )
                      } }
                    ></Input>
                  </FormGroup>

                  <div className="btn-group">
                    <Button
                      className="mt-2 btn-lg text-center mr-2"
                      color="primary"
                      // type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navigate("/crowd/campaign/create/funding");
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      className="mt-2 btn-lg btn-green text-center"
                      type="submit"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   e.stopPropagation();

                      //   // @todo Review the campaign
                      //   props.CrowdCampaign_Content({
                      //     img: doc,
                      //   });

                      //   navigate("/crowd/campaign/create/summary");
                      // }}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </Form>
              </Col>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

// export default CreateCampaignContent;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CreateCampaignContent);

/**
 */
const DescComp = (props) => {
  const [type, Type] = React.useState(props.t); // txt / img / vidUrl
  const [text, Text] = React.useState('txt' === props.t ? props.d : '');

  const imgRef = useRef();
  const [imgDoc, ImgDoc] = React.useState('img' === props.t && props.d ? props.d : {});

  const OnChangeTxt = (e) => {
    Text(e.target.value);
    props.OnChange('txt', e.target.value);
  };

  // const OnBlur = e => {
  //   props.OnChange(desc);
  // }

  const HandleFileRead = ( event ) => {
    // const { name, value } = event.target;
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    const doc = {
      file: file,
      type: file.type,
      name: file.name,
      path: url,
    };

    if (!file) return { msg: "Image not found" };

    ImgDoc({ ...doc });
    props.OnChange('img', doc);
  };

  return (
    <>
      <>
        {"txt" === type ? (
          <textarea
            // key={i}
            className="form-control col-sm-8 mt-3"
            style={{
              maxWidth: "90%",
              border: "1px solid #e3e3e3",
              borderRadius: 4,
            }}
            rows="20"
            col="50"
            id={["social", props.key].join("-")}
            placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
            type="text"
            value={text}
            onChange={OnChangeTxt}
            // onBlur={OnBlur}
          ></textarea>
        ) : "img" === type ? (
          <div>
            <Image
              src={imgDoc.path}
              alt={require("assets/img/placeholder.png").default}
              altTxt="image"
              cn="border"
              style={{
                width: 400,
                maxHeight: 300,
                minHeight: 300,
                objectFit: "cover",
              }}
            />
            <Button
              className="btn btn-primary input-group-append"
              for="btn-check"
              style={{ background: "#A3ACB8" }}
              onClick={(e) => {
                e.preventDefault();
                imgRef.current.click();
              }}
            >
              {/* Image */}
              Select
            </Button>
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              id="contained-button-file"
              ref={imgRef}
              onChange={HandleFileRead}
            />
          </div>
        ) : "vid" === type ? (
          <div>{/*  */}</div>
        ) : null}
      </>

      <div className="mt-3 col-sm-11 btn-group p-0">
        <button
          className="btn btn-green mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("txt");
          }}
        >
          Text
        </button>
        <button
          className="btn btn-green mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("img");
          }}
        >
          Image
        </button>
        <button
          className="btn btn-green mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("vid");
          }}
        >
          Video
        </button>
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.OnRem();
          }}
        >
          <i className="fa fa-trash text-white" /> Remove
        </button>
      </div>
    </>
  );
}; // DescComp
