/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container, Row } from "reactstrap";

function CharityFooter() {
  return (
    <footer
      className="narrow-footer text-white"
      style={{ backgroundColor: "#222222" }}
    >
      <div
        className="p-3 pb-0"
        style={{ backgroundColor: "rgba(255,255,255,0.05)" }}
      >
        <Container className="p-0">
          <ul class="list-inline">
            <li>
              <a href="http://littlehope.org.uk/">Home</a>
            </li>
            <li>
              <a href="http://littlehope.org.uk/appeals/">Appeals</a>
            </li>
            <li>
              <a href="http://littlehope.org.uk/contact/">Contact</a>
            </li>
            <li>
              <a href="/">My Sadaqa</a>
            </li>
            <li>
              <a href="http://littlehope.org.uk/donate/">Donate</a>
            </li>
          </ul>
        </Container>
      </div>
      <Container
        className="copyright p-2"
        style={{ backgroundColor: "#171717;" }}
      >
        Developed by &nbsp;
        <a href="http://megalnt.com" style={{ color: "#666666" }}>
          Megalogic & Technology
        </a>
      </Container>
    </footer>
  );
}

export default CharityFooter;
