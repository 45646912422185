import React from "react";
import "./style.css";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
} from "reactstrap";
// core components
const items = [
  {
    // imgsrc: require("assets/img/campaigns/masjid.png").default,
    title: "Eye Camp in Bangladesh",
    title2: "Gabe's memorial fund",
    description:
      "Imagine your vision is getting worse day by day and you do not know it is CURABLE!!",
    lastdonatehr: "9",
    targetamount: "1000",
    receiveamount: "220",
    src: "/campaign/single_camp_eye_camp",
  },
  {
    // imgsrc: require("assets/img/campaigns/water.png").default,
    title: "Water Well",
    title2: "Gabe's memorial fund",
    description:
      "People in Bangladesh walk for miles to reach the nearest water source just to get",
    lastdonatehr: "9",
    targetamount: "1000",
    receiveamount: "220",
    src: "/campaign/single_camp_water_well",
  },
  // {
  //   src: require("assets/img/campaigns/clinic.png").default,
  //   title: "Dental Clinic",
  //   title2: "Gabe's memorial fund",
  //   description:
  //     "Many people are suffering from oral diseases in Somalia and Kenya. SEND A LITTLE HOPE is providing treatment to those people of Somalia and Kenya.",
  //   lastdonatehr: "9",
  //   targetamount: "1000",
  //   receiveamount: "220",
  // },
  // {
  //   src: require("assets/img/campaigns/food.png").default,
  //   title: "Emergency Food",
  //   title2: "Gabe's memorial fund",
  //   description:
  //     "Mapping food insecure areas, Support resident-driven processes to guide equitable food policy.",
  //   lastdonatehr: "9",
  //   targetamount: "1000",
  //   receiveamount: "590",
  // },
  // {
  //   src: require("assets/img/campaigns/emergency-water.png").default,
  //   title: "Emergency Water",
  //   title2: "Gabe's memorial fund",
  //   description:
  //     "Provide water to bring life into existence, to deliver water we need water truck to deliver them to peoples door steps.",
  //   lastdonatehr: "9",
  //   targetamount: "1000",
  //   receiveamount: "600",
  // },
  // {
  //   src: require("assets/img/fund-3.jpeg").default,
  //   title: "Free Education",
  //   title2: "Gabe's memorial fund",
  //   description:
  //     "Free Education to bring life into existence, to establish education we need schools, uniform, books to deliver them to peoples door steps.",
  //   lastdonatehr: "9",
  //   targetamount: "1000",
  //   receiveamount: "280",
  // },
];

function FundraisersLittlehope() {
  return (
    <>
      <div className="section section-fundraisers mt-5" id="basic-elements">
        <Container>
          <h2 className="title">Find Little Hope Foundation fund near you</h2>
          <Row>
            {items.map((item) => {
              return (
                <div className="col-md-4 col-sm-12">
                  <Card>
                    <img
                      alt="..."
                      className="rounded card-img"
                      src={item.imgsrc}
                      style={{}}
                    ></img>
                    <CardBody>
                      <CardTitle tag="h4" className="text-uppercase mt-0">
                        {item.title}
                      </CardTitle>
                      <CardTitle tag="h5">{item.title2}</CardTitle>
                      <CardText className="fund-description">
                        {item.description}
                      </CardText>

                      <CardTitle tag="h3" className="font-weight-bold">
                        <a
                          href="{item.src}"
                          class="btn btn-sm btn-green text-white"
                        >
                          View Details
                        </a>
                      </CardTitle>
                    </CardBody>
                  </Card>
                </div>
              );
            })}
          </Row>

          <Row lg="12">
            <div className="col text-center">
              <button className="btn btn-md btn-green text-center">
                <a href="/campaign" class="text-white">
                  All campaign
                </a>
              </button>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FundraisersLittlehope;
