/**
 *	@copyright	KARD.
 */

import React from "react";

import { navigate, Link } from "@reach/router";

// reactstrap components
import {
  Button,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
  Container,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Password from "./password";

/**
 */
function LoginPage(props) {
  const [isBusy, IsBusy] = React.useState(false);
  const [email, Email] = React.useState("");
  const [password, SetPassword] = React.useState(null);

  console.log( "!!!!!!!!!!!!!!!!!!! props : ", props );
  /**
   */
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  /**
   */
  const OnLogin = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    Login().catch((err) => {});
  }; // OnLogin

  /**
   */
  const Login = async () => {
    try {
      IsBusy(true);

      const usr_obj = await props.Auth_Login({
        email,
        pass: password.p,
      });

      // console.log( 'auth:Login: Login: usr_obj: ', usr_obj )

      if (usr_obj.challengeName === "NEW_PASSWORD_REQUIRED") {
        // console.log( 'navigating to /auth/complete' )

        navigate("/auth/complete");
      } else {

        if( props.location.state && props.location.state.fromUri )
          navigate( props.location.state.fromUri )
        else
          navigate("/");
      }


      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("auth:Login: Login: err: ", err);

      alert(
        "Login failed. Check you username and password or create new account."
      );

      IsBusy(false);

      return { err };
    }
  }; // Login

  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section login" style={{ marginTop: "2rem" }}>
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <h3 className="text-center">Sign In</h3>
              <Form onSubmit={OnLogin}>
                <FormGroup>
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{ padding: "10px" }}>
                        <i className="fa fa-user-circle"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="user@fundraiser.com"
                      type="email"
                      value={email}
                      onChange={(e) => Email(e.target.value)}
                      required
                    />
                  </InputGroup>
                  <FormText
                    className="text-muted"
                    color="default"
                    id="emailHelp"
                  >
                    We'll never share your email with anyone else.
                  </FormText>
                </FormGroup>

                <Password getPassword={SetPassword}/>


                <FormGroup check>
                  <Link to="/auth/forgotten">
                    <label className="font-weight-bold text-info">
                      Forgot Your Password
                    </label>
                  </Link>
                </FormGroup>

                <Button
                  color="info"
                  type="submit"
                  style={{ width: "100%" }}
                  // onClick={OnLogin}
                >
                  LOG IN
                </Button>
                <p className="text-center acc-msg">
                  Dont Have An Account?{" "}
                  <Link to="/auth/register">
                    <a
                      href="/registration"
                      className="font-weight-bold"
                      style={{ color: "#2ca8ff" }}
                    >
                      Register
                    </a>
                  </Link>
                </p>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(LoginPage);
