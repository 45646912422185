import React from "react";

import { navigate } from "@reach/router";

import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { useDispatch } from "react-redux";
import { FeaturedNewsList, NewsList } from "../../rdx/actions/news";
import { Time } from "../../api/Utils";
import * as PropTypes from "prop-types";
import Loader from "react-js-loader";
import { CircularProgress } from "@material-ui/core";

function NewsItem(props) {
  const { news } = props;
  return (
    <Col sm="6" md="4" lg="4" className="mb-5">
      <div className="card card-cascade h-100">
        <div className="view view-cascade overlay">
          <img
            className="card-img-top"
            style={{
              maxHeight: 241,
              minHeight: 240,
              padding: 10,
              borderRadius: 15,
            }}
            src={news.url}
            alt="Card image cap"
          />
          <a href="#">
            <div className="mask rgba-white-slight"></div>
          </a>
        </div>

        <div className="card-body card-body-cascade">
          <h6 className="pink-text pb-2 pt-1">
            <i className="fas fa-cog"></i> New
            <span className="text-muted pull-right">
              {Time.Epoch2DateShort(news.tn)}
            </span>
          </h6>

          <a
            style={{ color: "#2680c2" }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              navigate(["/news", news.tn].join("/"), {
                state: news,
              });
            }}
            className="font-weight-bold card-title"
          >
            {news.t}
          </a>

          <p className="card-text mt-3">{news.sn}</p>

          <button
            // href={item.link}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              navigate(["/news", news.tn].join("/"), {
                state: news,
              });
            }}
            className="btn cmpBtn text-white"
            style={{ fontSize: 12 }}
          >
            Read More
          </button>
        </div>
      </div>
    </Col>
  );
}

NewsItem.propTypes = { news: PropTypes.any };

function News() {
  const dispatch = useDispatch();
  const [newsList, SetNewsList] = React.useState([]);
  const [featuredNewsList, SetFeaturedNewsList] = React.useState([]);
  const [lastKey, SetLastKey] = React.useState(null);
  const [featuredLastKey, SetFeaturedLastKey] = React.useState(null);
  const [isLoading, IsLoading] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    initNews().then().catch();
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const initNews = async () => {
    try {
      IsLoading(true);
      await getFeaturedNewsList();
      await getNewsList();
      IsLoading(false);
    } catch (e) {
      console.warn(e);
      return Promise.reject(e);
    }
  };
  const getNewsList = async () => {
    try {
      const params = {
        last_key: lastKey,
      };
      const { Items, LastEvaluatedKey } = await dispatch(NewsList(params));
      const resp = await dispatch(FeaturedNewsList(params));
      //console.log(Items, LastEvaluatedKey);
      SetNewsList(lastKey ? [...newsList, ...Items] : [...Items]);
      SetLastKey(LastEvaluatedKey);
    } catch (e) {
      console.warn(e);
    }
  };
  const getFeaturedNewsList = async () => {
    try {
      const params = {
        last_key: featuredLastKey,
      };
      const { Items, LastEvaluatedKey } = await dispatch(
        FeaturedNewsList(params)
      );
      //console.log(Items, LastEvaluatedKey);
      SetFeaturedNewsList(lastKey ? [...newsList, ...Items] : [...Items]);
      SetFeaturedLastKey(LastEvaluatedKey);
    } catch (e) {
      console.warn(e);
    }
  };
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white title p-0">
                News
              </h3>
            </Container>
          </div>
        </div>
        <div className="section section-image-gallery">
          <Container>
            <h4 className="title">Featured News</h4>
            <Row style={{ overflow: isLoading ? "hidden" : "" }}>
              {isLoading ? (
                <CircularProgress
                  color="primary"
                  size={200}
                  style={{ top: "30%", left: "38%", position: "absolute" }}
                />
              ) : null}
              {featuredNewsList.map((item) => {
                return <NewsItem news={item} />;
              })}
            </Row>
            <h4 className="title">All News</h4>
            <Row style={{ overflow: isLoading ? "hidden" : "" }}>
              {isLoading ? (
                <CircularProgress
                  color="primary"
                  size={200}
                  style={{ top: "30%", left: "38%", position: "absolute" }}
                />
              ) : null}
              {newsList.map((item) => {
                // console.log(item);
                return <NewsItem news={item} />;
              })}
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default News;
